import Media from "./Media";
import { PlaceAndMedia } from "./PlaceAndMedia";

export default class PlacesAndMediaList
{
    private _length: number = -1;
    private _currentIndex: number = 0;

    private readonly windowSize = 4;

    constructor(private _placesAndMedia: PlaceAndMedia[]){
        this._length = this._placesAndMedia.length;
    }

    get length(): number{
        return this._length;
    }

    get currentIndex(): number{
        return this._currentIndex;
    }

    getWindow(): PlaceAndMedia[]{
        const endIndex = Math.min(this.length, this.currentIndex + this.windowSize);
        return this._placesAndMedia.slice(this.currentIndex, endIndex);
    }

    hasNext(): boolean{
        return (this.length >= this.windowSize + 1) && (this.currentIndex < this.length - this.windowSize);
    }

    hasPrevious(): boolean{
        return (this.length >= this.windowSize + 1) && (this.currentIndex > 0);
    }

    next(){
        if(!this.hasNext()) return;
        this._currentIndex += 1; 
    }

    previous(){
        if(!this.hasPrevious()) return;
        this._currentIndex -= 1; 
    }
}