import { db } from './model/Db';

import threeMain from './three/main';

import MainApp from './svelte/MainApp.svelte';
import TweetApp from './svelte/TweetApp.svelte';
import { tweetApiService } from './services/TweetApi';

async function main(){
    await db.load();

    threeMain();

    tweetApiService.start();
    
    const mainDomTarget = document.getElementById('main-ui');
    const mainApp = new MainApp({
        target: mainDomTarget,
        props: { }
    });
    
    const tweetAppDomTarget = document.getElementById('tweet-ui');
    const tweetApp = new TweetApp({
        target: tweetAppDomTarget,
        props: { }
    });
}


main();