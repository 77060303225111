
import {
    BufferGeometry,
    LineBasicMaterial,
    Line,
    Vector3,
    Scene,
} from 'three';


export default class SimpleLine
{

    private line: Line;

    private requiresUpdate: boolean = false;

    public set color(newColor: number){
        (this.line.material as any).color.set(newColor);
    }

    constructor(private _start: Vector3, private _end: Vector3){
        const mat = new LineBasicMaterial({ color: 0x0000ff });

        const points = [
            this._start,
            this._end,
        ];

        const geometry = new BufferGeometry().setFromPoints(points);

        this.line = new Line(geometry, mat);
    }

    get start(): Vector3{
        return this._start;
    }

    set start(start: Vector3){
        this._start = start;
        this.requiresUpdate = true;
    }

    get end(): Vector3{
        return this._end;
    }

    set end(end: Vector3){
        this._end = end;
        this.requiresUpdate = true;
    }

    public addToScene(scene: Scene){
        scene.add(this.line);
    }

    public show(){
        this.line.visible = true;
    }

    public hide(){
        this.line.visible = false;
    }

    public update(){
        if(!this.requiresUpdate) return;

        this.requiresUpdate = false;

        // expressed as float[] x,y,z,x,y,z,...
        const positions = (this.line.geometry as any).attributes.position.array;
        positions[0] = this._start.x;
        positions[1] = this._start.y;
        positions[2] = this._start.z;

        positions[3] = this._end.x;
        positions[4] = this._end.y;
        positions[5] = this._end.z;

        (this.line.geometry as any).attributes.position.needsUpdate = true;
    }
}
