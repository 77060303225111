import {
    Texture,
    TextureLoader,
    Mesh,
    PlaneGeometry,
    Vector3,
    DoubleSide,
    MeshBasicMaterial,
    Intersection,
    SphereGeometry,
    Scene,
} from 'three';

import { BaseEventProvider, Listener, MyEvent } from '../common/BaseEventProvider';

import {
    interactionEventProvider, 
    InteractionEventType, 
    instersectsContainObject 
} from '../common/InteractionEventProvider';
import { I_InterviewDigest, Place } from '../model/CommonTypes';
import { db } from '../model/Db';

import Media, { MediaSimplifiedFile } from '../model/Media';
import { SmartPlace } from '../model/SmartPlace';
import SimpleLine from './SimpleLine';
import { $textureCache } from './TextureCache';

export const FloatingImageEventType = {
    click: "click",
}

const SPHERE = new SphereGeometry(7);
const SPHERE_MAT = new MeshBasicMaterial( {color: 0x000000} );

export default class FloatingImage extends BaseEventProvider
{
    private _mesh !: Mesh; 
    
    private media !: Media;
    private place!: SmartPlace;

    public scaleMultiplier: number = 70;

    private interactionHandler: Listener;

    private latestUrl!: string;

    public set color(newCol:string){
        console.log("color", newCol);
        const color = parseInt(newCol.substring(1), 16);
        this.simpleLine.color = color;
        (this.startPointSphere.material as any).color.set(color);
        (this.endPointSphere.material as any).color.set(color);
    }

    private simpleLine: SimpleLine = new SimpleLine(new Vector3(0, 0, 0), new Vector3(0, 0, 0));
    
    private endPointSphere: Mesh = new Mesh(SPHERE, SPHERE_MAT);
    private startPointSphere: Mesh = new Mesh(SPHERE, SPHERE_MAT); // on the "ground"

    constructor(){
        super();
        const width = 1;
        const height = 1;
        const geometry = new PlaneGeometry(width, height);
        const material = new MeshBasicMaterial({color: 0xffffff, side: DoubleSide});
        this._mesh = new Mesh(geometry, material);
        
        this.interactionHandler = this.onInteract.bind(this);

        interactionEventProvider.addEventListener(InteractionEventType.mousemove, this.interactionHandler);
        interactionEventProvider.addEventListener(InteractionEventType.click, this.interactionHandler);

        this.endPointSphere.scale.set(0.4, 0.4, 0.4);
    }

    public addToScene(scene: Scene){
        scene.add(this._mesh);
        scene.add(this.endPointSphere);
        scene.add(this.startPointSphere);
        this.simpleLine.addToScene(scene);
    }
    
    public activate(){
        interactionEventProvider.addInteractable(this._mesh);
    }

    public deActivate(){
        interactionEventProvider.removeInteractable(this._mesh);
    }

    public setScale(aspectRatio: number){
        this._mesh.scale.set(aspectRatio * this.scaleMultiplier, this.scaleMultiplier, 1);
    }

    public setTex(tex:Texture){
        const aspectRatio = tex?.image.width / tex?.image.height;
        this.setScale(aspectRatio);
        (this._mesh.material as any).map = tex;
        (this._mesh.material as any).needsUpdate = true;
    }

    public loadTexture(url: string, aspectRatio: number){
        // console.log("loading texture", url, aspectRatio);

        if($textureCache.hasUrl(url)){
            const tex = $textureCache.getTexForUrl(url) as Texture;            
            this.setTex(tex);
            return;
        }


        // this._mesh.scale.set(aspectRatio * this.scaleMultiplier, this.scaleMultiplier, 1);
        (this._mesh.material as any).map = null;
        (this._mesh.material as any).needsUpdate = true;

        new TextureLoader().load(url, 
            tex => {
                if(this.latestUrl != url){
                    // ignore loading if texture is not the latest;
                    return;
                }
                this.setTex(tex);
                // (this._mesh.material as any).map = tex;
                // (this._mesh.material as any).needsUpdate = true;
                $textureCache.persistTexForUrl(url, tex);
            },
            xhr => console.log("loading", (xhr.loaded / xhr.total) * 100, "% loaded"),
            err => console.error("texture load error for url", url, "error:", err)
        );
    }
    
    public loadMedia(media: Media, place: SmartPlace) {
        // console.log("loading media", media);
        this.media = media;
        this.place = place;

        // find texture to load
        // has mediaFilee ??
        const mediaFile: MediaSimplifiedFile | null = this.media.mediaFile;

        if(mediaFile){
            const aspectRatio = mediaFile.size!.width / mediaFile.size!.height;
            this.latestUrl = mediaFile.url;
            this.loadTexture(mediaFile.url, aspectRatio); 
            return;
        }

        // else handle issue

        if(this.media.type == "Audio"){
            const aspectRatio = 1;
            const url = "/images/floating_soundcloud.jpg";

            this.latestUrl = url;
            this.loadTexture(url, aspectRatio); 
            return;
        }
        
        if(this.media.type == "WebPage"){
            const aspectRatio = 1;
            const url = "/images/floating_webpage.jpg";
            this.latestUrl = url;

            this.loadTexture(url, aspectRatio); 
            return;
        }
        
        if(this.media.type == "Video"){
            const aspectRatio = 1;
            const url = "/images/floating_video.jpg";

            this.latestUrl = url;
            this.loadTexture(url, aspectRatio); 
            return;
        }
        
        if(this.media.type == "Interview"){
            const aspectRatio = 1;
            const url = "/images/floating_interview.jpg";

            this.latestUrl = url;
            this.loadTexture(url, aspectRatio); 
            return;
        }

    }

    public set position(newVecPos){
        this._mesh.position.set(newVecPos.x, newVecPos.y, newVecPos.z);
    }

    public get position(){
        return this._mesh.position;
    }

    public show(){
        this._mesh.visible = true;
        this.startPointSphere.visible = true;
        this.endPointSphere.visible = true;
        this.simpleLine.show();
    }

    public hide(){
        this._mesh.visible = false;
        this.startPointSphere.visible = false;
        this.endPointSphere.visible = false;
        this.simpleLine.hide();
    }

    public update(camPos: Vector3){
        this._mesh.lookAt(camPos);

        const heightOffset = 25;
        const startPosition: Vector3 =  new Vector3(this.media.position.x, this.media.position.y + heightOffset, this.media.position.z);
        let endPosition!: Vector3;
        // set up endPoint (sphere at bottom of image)
        {
            // https://stackoverflow.com/questions/35641875/three-js-how-to-find-world-orientation-vector-of-objects-local-up-vector
            const up = this._mesh.up.clone().applyQuaternion(this._mesh.quaternion );
            endPosition = this.position.clone().sub(up.multiplyScalar(this.scaleMultiplier / 2));
            const { x, y, z } = endPosition;
            this.endPointSphere.position.set(x, y, z);
        }


        // set ground sphere (start point)
        {
            const { x, y, z } = startPosition;
            this.startPointSphere.position.set(x, y, z);
        }

        {
            const { x, y, z } = startPosition;
            this.simpleLine.start = startPosition;
            this.simpleLine.end = endPosition;

            this.simpleLine.update();
        }
    }

    private onInteract(event:MyEvent){
        const intersects: Intersection[] = event.value as Intersection[];
        if(event.type == InteractionEventType.click){ 
            if (instersectsContainObject(intersects, this._mesh)){
                console.log("clicked on ", this.media);

                this.provide(FloatingImageEventType.click, {media: this.media, place: this.place });
            }
        }
    }
}