/* ts/svelte/TweetUI.svelte generated by Svelte v3.31.2 */
import {
	SvelteComponent,
	attr,
	binding_callbacks,
	check_outros,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	group_outros,
	init,
	insert,
	mount_component,
	safe_not_equal,
	transition_in,
	transition_out
} from "svelte/internal";

import TweetCard from "./TweetCard.svelte";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[4] = list[i];
	child_ctx[6] = i;
	return child_ctx;
}

// (13:4) {#each tweets as tweet, i}
function create_each_block(ctx) {
	let tweetcard;
	let current;

	tweetcard = new TweetCard({
			props: {
				text: /*tweet*/ ctx[4].text,
				intensity: /*tweet*/ ctx[4].intensity,
				currentSubcat: /*currentSubcat*/ ctx[1]
			}
		});

	return {
		c() {
			create_component(tweetcard.$$.fragment);
		},
		m(target, anchor) {
			mount_component(tweetcard, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const tweetcard_changes = {};
			if (dirty & /*tweets*/ 1) tweetcard_changes.text = /*tweet*/ ctx[4].text;
			if (dirty & /*tweets*/ 1) tweetcard_changes.intensity = /*tweet*/ ctx[4].intensity;
			if (dirty & /*currentSubcat*/ 2) tweetcard_changes.currentSubcat = /*currentSubcat*/ ctx[1];
			tweetcard.$set(tweetcard_changes);
		},
		i(local) {
			if (current) return;
			transition_in(tweetcard.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(tweetcard.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(tweetcard, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let current;
	let each_value = /*tweets*/ ctx[0];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div, "class", "tweets-container svelte-8rxaqm");
		},
		m(target, anchor) {
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div, null);
			}

			/*div_binding*/ ctx[3](div);
			current = true;
		},
		p(ctx, [dirty]) {
			if (dirty & /*tweets, currentSubcat*/ 3) {
				each_value = /*tweets*/ ctx[0];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
			/*div_binding*/ ctx[3](null);
		}
	};
}

let oldFirstTweet = "";

function instance($$self, $$props, $$invalidate) {
	let { tweets } = $$props;
	let { currentSubcat = "Reves" } = $$props;
	let domElement;

	function div_binding($$value) {
		binding_callbacks[$$value ? "unshift" : "push"](() => {
			domElement = $$value;
			$$invalidate(2, domElement);
		});
	}

	$$self.$$set = $$props => {
		if ("tweets" in $$props) $$invalidate(0, tweets = $$props.tweets);
		if ("currentSubcat" in $$props) $$invalidate(1, currentSubcat = $$props.currentSubcat);
	};

	return [tweets, currentSubcat, domElement, div_binding];
}

class TweetUI extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { tweets: 0, currentSubcat: 1 });
	}
}

export default TweetUI;