const root:HTMLElement = document.getElementById('labels-container') as HTMLElement;

if(!root){
    throw new Error("label root does not exist (#labels-container)");
}

import { Id, Tag } from '../model/CommonTypes'; 

import {
    Vector3,
    Camera,
} from 'three';
import { BaseEventProvider } from '../common/BaseEventProvider';
import { SmartPlace } from '../model/SmartPlace';
import { SubcategoryType, SubCatInfo } from '../model/SubcategoryHelpers';

////////////////
// https://threejsfundamentals.org/threejs/lessons/threejs-align-html-elements-to-3d.html

// displays a Subcat selector button
export default class SubCatLabel extends BaseEventProvider
{
    private rootElement: HTMLElement;
    private selected: boolean = false;

    private clickHandler!: (e: MouseEvent) => void;

    constructor(private subcatInfo: SubCatInfo){
        super();

        this.rootElement = document.createElement('div');
        this.rootElement.classList.add('label');
        // this.rootElement.classList.add('fadable');

        root.appendChild(this.rootElement);

        const iconElement = document.createElement('img') as HTMLImageElement;
        iconElement.src = this.subcatInfo.icon;

        const contentElement = document.createElement('div');
        contentElement.classList.add('label-content');
        contentElement.innerText = this.subcatInfo.displayName;
        contentElement.style.color = this.subcatInfo.color;

        this.rootElement.appendChild(iconElement);
        this.rootElement.appendChild(contentElement);


        this.clickHandler = this.onClick.bind(this);
        this.rootElement.addEventListener("click", this.clickHandler);
    }

    onClick(e: MouseEvent){
        e.stopPropagation();
        e.preventDefault();
        if(this.selected) return;

        this.provide("selectionRequest", { subcatInfo: this.subcatInfo });
    }

    select(){
        if(this.selected) return;

        this.selected = true;
        this.rootElement.classList.add("selected");
    }

    unSelect(){
        if(!this.selected) return;
        
        this.selected = false;
        this.rootElement.classList.remove("selected");
    }

    // fadeIn(){
    //     this.rootElement.classList.add('fade-in');
    //     this.rootElement.classList.remove('fade-out');
    // }
    
    // fadeOut(){
    //     this.rootElement.classList.add('fade-out');
    // }

    // update(worldPos: Vector3, camera: Camera, canvas: HTMLCanvasElement){
        
    //     const tempV = worldPos.clone();
    //     // get the normalized screen coordinate of that position
    //     // x and y will be in the -1 to +1 range with x = -1 being
    //     // on the left and y = -1 being on the bottom
    //     tempV.project(camera);

    //     // convert the normalized position to CSS coordinates
    //     const x = (tempV.x * .5 + .5) * canvas.clientWidth;
    //     const y = (tempV.y * -.5 + .5) * canvas.clientHeight;

    //     // move the elem to that position
    //     this.rootElement.style.transform = `translate(-50%, -50%) translate(${x}px,${y}px)`;
    // }
}