import { Texture } from "three";

type url = string;
class TextureCache{
    private map:Map<url, Texture> = new Map<url, Texture>();

    public getTexForUrl(url:url): Texture | null{
        if(!this.map.has(url)){
            return null;
        }

        return this.map.get(url) as Texture;
    }

    public hasUrl(url: string): boolean {
        return this.map.has(url);
    }

    public persistTexForUrl(url:url, tex: Texture): void{
        if(!this.map.has(url)){
            this.map.set(url, tex);
        }
    }
}

export const $textureCache = new TextureCache();

