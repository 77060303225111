import { Vector3 } from 'three';
import { latLonToXY } from '../three/geo_utils';
import { MediaKey, Coordinates } from './CommonTypes';

export interface MediaSimplifiedFile
{
    id: string,
    url: string,
    size?: {
        width: number,
        height: number,
    }
}

export default class Media
{

    private _position!: Vector3; // worldPos

    constructor(private _data: any, private _type: MediaKey){
        this.computeWorldPos();
     }

    get id(): string{
        return this._data.id;
    }

    get title(): string{
        return this._data.TitleOfMedia;
    }

    get type(): MediaKey{
        return this._type;
    }

    get coordinates(): Coordinates{
        return this._data.Coordinates;
    }

    get position(): Vector3{
        return this._position;
    }

    get description(): string{
        return this._data.Description;
    }

    get mediaFile(): MediaSimplifiedFile | null{
        const mediaFile = this._data.MediaFile ? this._data.MediaFile[0] : null;

        if(!mediaFile) {
            return null;
        }

        const mediaFileLargeThumbnail = mediaFile.thumbnails.large;
        let result: MediaSimplifiedFile = {
            id: this.id,
            url: mediaFileLargeThumbnail.url,
            size: {
                width: mediaFileLargeThumbnail.width,
                height: mediaFileLargeThumbnail.height,
            },
        };
        return result;
    }
    
    computeWorldPos(){
        const coords = this._data.Coordinates;
        if(!coords){
            this._position = new Vector3(0, 0, 0);
            return;
        }
        const pos2D = latLonToXY(coords);
        this._position = new Vector3(pos2D.x, 0, pos2D.y);
    }
}