
import {
    Scene,
    PerspectiveCamera,
    WebGLRenderer,
    Color,
    BoxGeometry,
    SphereBufferGeometry,
    MeshBasicMaterial,
    MeshPhongMaterial,
    MeshDepthMaterial,
    DirectionalLight,
    AmbientLight,
    HemisphereLight,
    
    AudioListener,
    PositionalAudio,
    AudioLoader,
    
    Mesh,
    Gl,
    Vector3,
    PCFSoftShadowMap,
    PlaneBufferGeometry,
    MeshStandardMaterial,
    PointLight
} from 'three';

const TWEEN = require('@tweenjs/tween.js');

import { resizeRendererToDisplaySize } from './three_utils';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

import { PlacesManager } from './PlacesManager';

import { ThemeManager } from './ThemeManager';

import { db } from '../model/Db';

import Stats from 'three/examples/jsm/libs/stats.module.js';

import { latLonToXY } from './geo_utils';
import { Context, interactionEventProvider } from '../common/InteractionEventProvider';
import { SpatialAudiosCollection } from '../model/SpatialAudio';
import { SpatialAudio } from './SpatialAudio';
import { Coordinates } from '../model/CommonTypes';

export default function threeMain(){
    // setup scene
    const scene = new Scene();
    scene.background = new Color(0xeeeeee);
    
    // setup renderer
    const canvas = document.getElementById('threed-canvas') as HTMLCanvasElement;
    const renderer = new WebGLRenderer({ canvas: canvas, antialias: true });
    renderer.shadowMap.enabled = true;
    // renderer.shadowMap.type = PCFSoftShadowMap;
    resizeRendererToDisplaySize(renderer);

    // setup camera
    const aspectRatio = window.innerWidth / window.innerWidth;
    const fov = 45;
    const near = 0.1;
    const far = 10000;
    const camera = new PerspectiveCamera(fov, aspectRatio, near, far);
    camera.position.z = 500;

    // setup audiolistener
    const audioListener = new AudioListener();
    camera.add(audioListener);
    
    
    // setup orbit controls
    const controls = new OrbitControls(camera, canvas);
    controls.target.set(0, 0, 0);
    controls.enablePan = false;
    controls.minDistance = 800;
    controls.maxDistance = 3000;
    controls.minPolarAngle = Math.PI * 0.20;
    controls.maxPolarAngle = Math.PI * 0.40;

    controls.update();

    // const stats = new (Stats as any)() ;
    // document.getElementById('stats')?.append(stats.dom);
    // console.log(stats);
    // stats.dom.style.top = "90vh";
    
    // add lights
    {
        const color = 0xFFFFFF;
        const intensity = 1.2;
        const light = new DirectionalLight(color, intensity);
        light.position.set(40, 80, 140);
        // light.castShadow = true;

        //Set up shadow properties for the light
        light.shadow.mapSize.width = 2048; // default
        light.shadow.mapSize.height = 2048; // default
        light.shadow.camera.near = 0.1; 
        light.shadow.camera.far = 500; 
        light.shadow.camera.left = -500; 
        light.shadow.camera.right = 500; 
        light.shadow.camera.top = 500; 
        light.shadow.camera.bottom = -500; 
        scene.add(light);
    }
    
    // {
    //     const color = 0xFFFFFF;
    //     const intensity = 1;
    //     const light = new PointLight(color, intensity);
    //     light.position.set(40, 280, 40);
    //     light.castShadow = true;

    //     //Set up shadow properties for the light
    //     light.shadow.mapSize.width = 1024; // default
    //     light.shadow.mapSize.height = 1024; // default
    //     // light.shadow.camera.near = 0.1; // default
    //     // light.shadow.camera.far = 500; // default
    //     scene.add(light);
    // }

    {
        const color = 0xFFFFFF;
        const intensity = 0.4;
        const light = new AmbientLight(color, intensity);
        scene.add(light);
    }

    {
        const skyColor = 0xB1E1FF;  // light blue
        const groundColor = 0xB97A20;  // brownish orange
        const intensity = 0.2;
        const light = new HemisphereLight(skyColor, groundColor, intensity);
        scene.add(light);
    }

    // {
    //     // add ground
    //     const planeSize = 4000;
    //     const planeGeo = new PlaneBufferGeometry(planeSize, planeSize);
    //     const planeMat = new MeshStandardMaterial({ color: 0xaaaaaa });
    //     const planeMesh = new Mesh(planeGeo, planeMat);
    //     // planeMesh.rotation.x = Math.PI * -.5;
    //     planeMesh.receiveShadow = true;
    //     planeMesh.rotation.x = Math.PI * -.5;
    //     planeMesh.position.y = -10;
    //     scene.add(planeMesh);
    // }
    
    {
        // load spatialAudios
        for(const descriptor of SpatialAudiosCollection){
            const spatialAudio = new SpatialAudio(
                descriptor.filename, 
                descriptor.coordinates as Coordinates, 
                descriptor.refDistance,
                descriptor.rollof,
                audioListener
            );
            spatialAudio.load();
            scene.add(spatialAudio.root);
        }
    }
    
    // themeManager
    const themeManager = new ThemeManager(scene);
    
    // Instantiate a loader
    const loader = new GLTFLoader();

    // // Optional: Provide a DRACOLoader instance to decode compressed mesh data
    // const dracoLoader = new DRACOLoader();
    // dracoLoader.setDecoderPath( '/examples/js/libs/draco/' );
    // loader.setDRACOLoader( dracoLoader );

    // Load a glTF resource (the map)
    loader.load(
        // resource URL
        'model_5.glb',
        // called when the resource is loaded
        function (gltf) {

            scene.add(gltf.scene);

            // gltf.scene.scale.y = 0.2;

            const buildingNames = [
                "map_2osm_buildings", // root
                "map_2.osm_buildings_1", // walls
                "map_2.osm_buildings_2", // roofs
            ];

            const simpleWireMat = new MeshPhongMaterial( {
                color: 0xaaaaaa,
                // transparent: true,
                // opacity:0.7,
                wireframe: false, 
            });        
            
            const redSimpleWireMat = new MeshPhongMaterial( {
                color: 0xbb2222,
                // transparent: true,
                // opacity:0.7,
                wireframe: false, 
            });         
            

            const celadonSimpleWireMat = new MeshPhongMaterial( {
                color: 0x9da8a4,
                // transparent: true,
                // opacity:0.7,
                wireframe: false, 
            });         

            gltf.scene.traverse((item: any) => {
                console.log("item.name", item.name);
                // item.castShadow = true;
                // item.receiveShadow = true;
                item.needsUpdate = true;
                
                if(item.name.endsWith("Vermilion")){
                    item.material = redSimpleWireMat;
                }else if(item.name.endsWith("Celadon")){
                    item.material = celadonSimpleWireMat;
                }else{
                    item.material = simpleWireMat;
                }
                // if(item.name == "batiments"){
                //     item.material = simpleWireMat;
                // }
            });

            gltf.animations; // Array<THREE.AnimationClip>
            gltf.scene; // THREE.Group
            gltf.scenes; // Array<THREE.Group>
            gltf.cameras; // Array<THREE.Camera>
            gltf.asset; // Object

            themeManager.addGltfScene(gltf.scene);
            themeManager.toggle();

        },
        // called while loading is progressing
        function (xhr) {
            console.log((xhr.loaded / xhr.total * 100) + '% loaded');
        },
        // called when loading has errors
        function (error) {
            console.log('error loading geometry:', error);
        }
    );
    
    let previousT = 0;

    const context: Context = {
        scene: scene,
        camera: camera,
        canvas: canvas,
    }

    interactionEventProvider.init(context);
    interactionEventProvider.start();

    const placesManager = new PlacesManager(context);


    function update(timeMS?: number) {
        if (resizeRendererToDisplaySize(renderer)) {
            const canvas = renderer.domElement;
            camera.aspect = canvas.clientWidth / canvas.clientHeight;
            camera.updateProjectionMatrix();
        }

        // compute deltaTime;
        timeMS = timeMS || 16; // we get undefined first frame;
        const elapsedMS = timeMS - previousT; // elapsedMS = deltaTime;
        previousT = timeMS;

        // update world
        controls.update();
        
        placesManager.update();

        // kubeParticleManager.update(elapsedMS);
        // imagePlayer.update(camera.position);
        
        // quoteManager.update(camera, canvas);
        
        TWEEN.update();
        renderer.render(scene, camera);
        // stats.update();

        requestAnimationFrame(update);
    }

    {
        // setup camera aspect Ratio
        const canvas = renderer.domElement;
        camera.aspect = canvas.clientWidth / canvas.clientHeight;
        camera.updateProjectionMatrix();
    }

    update();

}