import { 
    Vector2, 
    Raycaster, 
    Scene, 
    Camera, 
    Object3D, 
    Intersection 
} from 'three';
import { runInThisContext } from 'vm';

import { BaseEventProvider } from './BaseEventProvider';

export const InteractionEventType = {
    mousemove: "mousemove",
    click: "click",
};

export interface Context {
    scene: Scene,
    camera: Camera,
    canvas: HTMLCanvasElement,
};

class InteractionEventProvider extends BaseEventProvider
{
    private _inited = false;
    private context!:Context;
    private mouse = new Vector2();
    private raycaster = new Raycaster();
    private interactablesSet = new Set<Object3D>();

    private mouseMoveHandler!: (e: MouseEvent) => void;
    private mouseClickHandler!: (e: MouseEvent) => void;
    private touchEndHandler!: (e: TouchEvent) => void;
    
    constructor(){
        super();
    }

    init(context: Context){
        if(this._inited) return;

        this.context = context;

        this.mouseMoveHandler = this.onMouseMove.bind(this);
        this.mouseClickHandler = this.onMouseClick.bind(this);
        this.touchEndHandler = this.onTouchEnd.bind(this);
        this._inited = true;
    }

    addInteractable(object3d: Object3D){
        this.interactablesSet.add(object3d);
    }

    removeInteractable(object3d: Object3D){
        this.interactablesSet.delete(object3d);
    }

    onMouseMove(e: MouseEvent){
        e.preventDefault();
        this.updateMouse(e);
        this.computeIntersection(this.context, InteractionEventType.mousemove);
    }

    updateMouse(e: MouseEvent){
        const canvas = this.context.canvas;
    
        // get relative mouse position (with respect to the canvas)
        const rect = (e as any).target.getBoundingClientRect();
        const x = e.clientX - rect.left; //x position within the element.
        const y = e.clientY - rect.top;  //y position within the element.
        // console.log(x, y, x / canvas.clientWidth, y / canvas.clientHeight);
        this.mouse.x = (x / canvas.clientWidth) * 2 - 1;
        this.mouse.y = - (y / canvas.clientHeight) * 2 + 1;
    }
    
    updateMouseFromTouch(e: TouchEvent){
        const canvas = this.context.canvas;

        const touch: Touch = e.changedTouches[0];
    
        // get relative mouse position (with respect to the canvas)
        const rect = (touch.target as any).getBoundingClientRect();
        // const x = e.clientX - rect.left; //x position within the element.
        // const y = e.clientY - rect.top;  //y position within the element.

        const x = touch.clientX - rect.left;
        const y = touch.clientY - rect.top;
        console.log("touch x y ", x, y);
        // console.log(x, y, x / canvas.clientWidth, y / canvas.clientHeight);
        this.mouse.x = (x / canvas.clientWidth) * 2 - 1;
        this.mouse.y = - (y / canvas.clientHeight) * 2 + 1;
    }

    onMouseClick(e: MouseEvent){
        e.preventDefault();
        e.stopPropagation();
        this.updateMouse(e);
        this.computeIntersection(this.context, InteractionEventType.click);
        console.log("onMouse Click");
    }

    onTouchEnd(e: TouchEvent){
        e.preventDefault();
        e.stopPropagation();
        console.log("on touch end", e);
        this.updateMouseFromTouch(e);
        this.computeIntersection(this.context, InteractionEventType.click);
    }

    computeIntersection(context: Context, eventType: string){
        this.raycaster.setFromCamera(this.mouse, context.camera);

        const targets: Object3D[] = Array.from(this.interactablesSet);
        const intersects = this.raycaster.intersectObjects(targets);
        this.provide(eventType, intersects);
    }

    start(){
        // document.addEventListener('mousemove', this.mouseMoveHandler);
        this.context.canvas.addEventListener('click', this.mouseClickHandler);
        this.context.canvas.addEventListener('touchend', this.touchEndHandler, false);
    }

    stop(){
        // document.removeEventListener('mousemove', this.mouseMoveHandler);
        this.context.canvas.removeEventListener('click', this.mouseClickHandler);
        this.context.canvas.addEventListener('touchend', this.touchEndHandler, false);
    }
}

export function instersectsContainObject(intersections: Intersection[], obj: Object3D): boolean{
    if(!intersections) return false;
    if(intersections.length == 0) return false;

    return !!intersections.find((intersection: Intersection) => intersection.object === obj);
}

export const interactionEventProvider = new InteractionEventProvider();