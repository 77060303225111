// import data from "../data/data.json";
import { Vector3 } from 'three';
import { latLonToXY } from '../three/geo_utils';
import { cacheBustUrl, randomItemFromArray } from '../utils';

import { 
    Id, 
    MediaKey, 
    TypeOfMedia, 
    MediaAirtable,
    Category,
    Subcategory, 
    Contributor,
    Place, 
    Label,
    Tag,
    I_InterviewDigest
} from './CommonTypes';

import Media from './Media';
import { SmartPlace } from './SmartPlace';
import { SubcategoryOrderList, SubcategoryType, subcatInfoForSubcatType } from './SubcategoryHelpers';


class Db
{

    private _data: any = null;
    private _loaded = false;
    private _mediaTypes = new Map<MediaKey, TypeOfMedia>();
    private _media: Media[] = [];
    private _places: SmartPlace[] = [];
    private _placesMap: Map<SubcategoryType, SmartPlace[]> = new Map<SubcategoryType, SmartPlace[]>();

    private _mediaBySubcatType: Map<SubcategoryType, Media[]> = new Map<SubcategoryType, Media[]>();


    private _interviewedPeople = null;

    async __loadData(){
    }

    async __loadPaths(){
    }

    async load(){
        const loadData = async () => {
            const dataURL = cacheBustUrl('./data/cr_media.json');
            const response = await fetch(dataURL);
            return await response.json();
        }

        this._data = await loadData();
        this._loaded = true;

        this.populateMediaTypes();
        this.populateMedia();
        this.buildMediaMap();

        this.enrichPlaces();
        this.buildPlacesMap();

        console.log("db load complete ---------------------");
    }

    populateMediaTypes(){
        this._data.TypeOfMedia.forEach((mt: TypeOfMedia) => {
            console.log("type of media", mt);
            this._mediaTypes.set(mt.TypeOfMedia, mt);
        });
    }

    populateMedia(){
        this._media = this._data.Media
        .map((mediaItem: MediaAirtable) => {
            const typeId = mediaItem.TypeOfMedia[0];
            const mediaKey: MediaKey = this._data.TypeOfMedia.find((item: TypeOfMedia) => item.id === typeId).TypeOfMedia;
            return new Media(mediaItem, mediaKey);
        });
    }

    buildMediaMap(){

        for(const subcatType of SubcategoryOrderList){
            const info = subcatInfoForSubcatType(subcatType);
            const subcatId = info.id;
            const collection = [];

            const airtableSubcat = this._data.Subcategories.find((item:any) => item.id == subcatId);
            for(const mediaId of airtableSubcat.Media){
                const media = this.getMediaWithId(mediaId);
                if(media){
                    collection.push(media);
                }
            }

            this._mediaBySubcatType.set(subcatType, collection);
        }
    }

    getMediasForSubcatType(subcatType: SubcategoryType): Media[]{
        return this._mediaBySubcatType.get(subcatType) as Media[];
    }

    mediaIsOfSubcatType(media:Media, subcatType:SubcategoryType): boolean{
        return !!this.getMediasForSubcatType(subcatType).find((m:Media) => media === m);
    }

    enrichPlaces(){
        this._data.Places = this._data.Places.map((place:Place) => {
            const pos2D = latLonToXY(place.coordinates);
            place.position = new Vector3(pos2D.x, 0, pos2D.y);
            return place;
        });


        // only show valid places (ie. place where tag is one of suvbcats of Category name 'thématique')
        {
            const thematicCategory: Category = this._data.Categories.find((cat:Category) => cat.NameOfCategory =="Thématique");
            if(!thematicCategory){
                throw new Error("no category with name 'Thématique'");
            }
            const thematicSubcategories: Id[] = thematicCategory.Subcategories;

            this._places = this._data.Places
                .map((p: Place) => new SmartPlace(p, thematicSubcategories))
                .filter((sp:SmartPlace) => sp.isShowableOnMap);
        }
    }

    buildPlacesMap(){
        for (const place of this.places){
            for(const tag of place.tags){
                const subcatType = tag.subcatType;
                if(this._placesMap.has(subcatType)){
                    const collection = this._placesMap.get(subcatType) as SmartPlace[];
                    collection.push(place);
                    this._placesMap.set(subcatType, collection);
                }else{
                    const collection = [place];
                    console.log("creating collection for", subcatType);
                    this._placesMap.set(subcatType, collection);
                }
            }
        }
    }

    getMediaWithId(id: Id): Media | null {
        const result = this._media.find(m => m.id === id);
        if(!result){
            console.warn("no media found with id", id);
        }
        return result || null;
    }

    public placesForSubcat(subcatType:SubcategoryType): SmartPlace[]{
        const result =  this._placesMap.get(subcatType) as SmartPlace[];
        if(!result){
            throw new Error(`unable to find places for subcat ${subcatType}`);
        }
        return result;
    }

    get media (): Media[]{
        return this._media;
    }

    get places(): SmartPlace[]{
        return this._places;
    }

    get labels(): Label[]{
        return this._data.Labels;
    }

    get subcategories(): Subcategory[]{
        return this._data.Subcategories;
    }

    findLabelWithId(id: Id): Label | null{
        return this.labels.find((l:Label) => l.id === id) || null;
    }

    findSubcategoryWithId(id:Id): Subcategory | null{
        return this.subcategories.find((s: Subcategory) => s.id === id) || null;
    }

    getMediaIdsForSubCategoryName(name: string): Id[]{
        const subcat = this._data.Subcategories.find(((s: Subcategory) => s.NameOfSubcategory === name));
        if(!subcat || !subcat.Media){
            return [];
        } 

        return subcat.Media;
    }

    getInterviewDigestFormMediaId(mediaId: Id): I_InterviewDigest | null{
        return this._data.InterviewDigests.find((item: I_InterviewDigest) => item.mediaId == mediaId);
    }

    getContributorWithId(id: Id): Contributor | null{
        return this._data.Contributors.find((c: Contributor) => c.id === id) || null;
    }
}
export const db = new Db(); 
