/* ts/svelte/Youtube.svelte generated by Svelte v3.31.2 */
import {
	SvelteComponent,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal
} from "svelte/internal";

import { onMount, onDestroy } from "svelte";
import getVideoId from "get-video-id";

function create_fragment(ctx) {
	let iframe;
	let iframe_src_value;

	return {
		c() {
			iframe = element("iframe");
			attr(iframe, "title", "youtube");
			if (iframe.src !== (iframe_src_value = /*src*/ ctx[0])) attr(iframe, "src", iframe_src_value);
			attr(iframe, "width", "100%");
			attr(iframe, "height", "100%");
			attr(iframe, "frameborder", "0");
		},
		m(target, anchor) {
			insert(target, iframe, anchor);
		},
		p(ctx, [dirty]) {
			if (dirty & /*src*/ 1 && iframe.src !== (iframe_src_value = /*src*/ ctx[0])) {
				attr(iframe, "src", iframe_src_value);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(iframe);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { clipUrl = null } = $$props;
	let src = "";

	onMount(() => {
		const videoId = getVideoId(clipUrl).id;
		$$invalidate(0, src = `https://www.youtube.com/embed/${videoId}?rel=0&theme=light`);
	});

	$$self.$$set = $$props => {
		if ("clipUrl" in $$props) $$invalidate(1, clipUrl = $$props.clipUrl);
	};

	return [src, clipUrl];
}

class Youtube extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { clipUrl: 1 });
	}
}

export default Youtube;