/* ts/svelte/MediaViewer.svelte generated by Svelte v3.31.2 */
import {
	SvelteComponent,
	create_component,
	destroy_component,
	detach,
	init,
	insert,
	mount_component,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

import ImageViewer from "./ImageViewer.svelte";
import ArrowScreen from "./ArrowsScreen.svelte";
import { jsToSvelteEventProvider, JsToSvelteEventType } from "./jsToSvelteBus";
import { svelteToJsEventProvider, SvelteToJsEventType } from "./svetleToJsBus";
import { onMount, onDestroy } from "svelte";
import ArrowsScreen from "./ArrowsScreen.svelte";
import SoundCloudPlayer from "./SoundCloudPlayer.svelte";
import WebPageViewer from "./WebPageViewer.svelte";
import YoutubePlayer from "./YoutubePlayer.svelte";
import InterviewDigestPlayer from "./InterviewDigestPlayer.svelte";
import { measureMemory } from "vm";

function create_fragment(ctx) {
	let imageviewer;
	let t0;
	let soundcloudplayer;
	let t1;
	let webpageviewer;
	let t2;
	let youtubeplayer;
	let t3;
	let interviewdigestplayer;
	let current;

	imageviewer = new ImageViewer({
			props: {
				isOpen: /*showImageViewer*/ ctx[0],
				payload: /*imageViewerPayload*/ ctx[1]
			}
		});

	imageviewer.$on("closeRequest", /*closeRequest_handler*/ ctx[13]);

	soundcloudplayer = new SoundCloudPlayer({
			props: {
				isOpen: /*showSoundCloudPlayer*/ ctx[2],
				payload: /*soundCloudPayload*/ ctx[3]
			}
		});

	soundcloudplayer.$on("closeRequest", /*closeRequest_handler_1*/ ctx[14]);

	webpageviewer = new WebPageViewer({
			props: {
				isOpen: /*showWebPageViewer*/ ctx[4],
				payload: /*webPagePayload*/ ctx[5]
			}
		});

	webpageviewer.$on("closeRequest", /*closeRequest_handler_2*/ ctx[15]);

	youtubeplayer = new YoutubePlayer({
			props: {
				isOpen: /*showYoutubePlayer*/ ctx[6],
				payload: /*youtubePayload*/ ctx[7]
			}
		});

	youtubeplayer.$on("closeRequest", /*closeRequest_handler_3*/ ctx[16]);

	interviewdigestplayer = new InterviewDigestPlayer({
			props: {
				isOpen: /*showInterviewDigestPlayer*/ ctx[8],
				payload: /*interviewDigestPayload*/ ctx[9]
			}
		});

	interviewdigestplayer.$on("closeRequest", /*closeRequest_handler_4*/ ctx[17]);

	return {
		c() {
			create_component(imageviewer.$$.fragment);
			t0 = space();
			create_component(soundcloudplayer.$$.fragment);
			t1 = space();
			create_component(webpageviewer.$$.fragment);
			t2 = space();
			create_component(youtubeplayer.$$.fragment);
			t3 = space();
			create_component(interviewdigestplayer.$$.fragment);
		},
		m(target, anchor) {
			mount_component(imageviewer, target, anchor);
			insert(target, t0, anchor);
			mount_component(soundcloudplayer, target, anchor);
			insert(target, t1, anchor);
			mount_component(webpageviewer, target, anchor);
			insert(target, t2, anchor);
			mount_component(youtubeplayer, target, anchor);
			insert(target, t3, anchor);
			mount_component(interviewdigestplayer, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const imageviewer_changes = {};
			if (dirty & /*showImageViewer*/ 1) imageviewer_changes.isOpen = /*showImageViewer*/ ctx[0];
			if (dirty & /*imageViewerPayload*/ 2) imageviewer_changes.payload = /*imageViewerPayload*/ ctx[1];
			imageviewer.$set(imageviewer_changes);
			const soundcloudplayer_changes = {};
			if (dirty & /*showSoundCloudPlayer*/ 4) soundcloudplayer_changes.isOpen = /*showSoundCloudPlayer*/ ctx[2];
			if (dirty & /*soundCloudPayload*/ 8) soundcloudplayer_changes.payload = /*soundCloudPayload*/ ctx[3];
			soundcloudplayer.$set(soundcloudplayer_changes);
			const webpageviewer_changes = {};
			if (dirty & /*showWebPageViewer*/ 16) webpageviewer_changes.isOpen = /*showWebPageViewer*/ ctx[4];
			if (dirty & /*webPagePayload*/ 32) webpageviewer_changes.payload = /*webPagePayload*/ ctx[5];
			webpageviewer.$set(webpageviewer_changes);
			const youtubeplayer_changes = {};
			if (dirty & /*showYoutubePlayer*/ 64) youtubeplayer_changes.isOpen = /*showYoutubePlayer*/ ctx[6];
			if (dirty & /*youtubePayload*/ 128) youtubeplayer_changes.payload = /*youtubePayload*/ ctx[7];
			youtubeplayer.$set(youtubeplayer_changes);
			const interviewdigestplayer_changes = {};
			if (dirty & /*showInterviewDigestPlayer*/ 256) interviewdigestplayer_changes.isOpen = /*showInterviewDigestPlayer*/ ctx[8];
			if (dirty & /*interviewDigestPayload*/ 512) interviewdigestplayer_changes.payload = /*interviewDigestPayload*/ ctx[9];
			interviewdigestplayer.$set(interviewdigestplayer_changes);
		},
		i(local) {
			if (current) return;
			transition_in(imageviewer.$$.fragment, local);
			transition_in(soundcloudplayer.$$.fragment, local);
			transition_in(webpageviewer.$$.fragment, local);
			transition_in(youtubeplayer.$$.fragment, local);
			transition_in(interviewdigestplayer.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(imageviewer.$$.fragment, local);
			transition_out(soundcloudplayer.$$.fragment, local);
			transition_out(webpageviewer.$$.fragment, local);
			transition_out(youtubeplayer.$$.fragment, local);
			transition_out(interviewdigestplayer.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(imageviewer, detaching);
			if (detaching) detach(t0);
			destroy_component(soundcloudplayer, detaching);
			if (detaching) detach(t1);
			destroy_component(webpageviewer, detaching);
			if (detaching) detach(t2);
			destroy_component(youtubeplayer, detaching);
			if (detaching) detach(t3);
			destroy_component(interviewdigestplayer, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { medias = [] } = $$props;
	let currentMedia = null;
	let currentMediaIndex = 0;
	let showImageViewer = false;
	let imageViewerPayload = null;
	let showSoundCloudPlayer = false;
	let soundCloudPayload = null;
	let showWebPageViewer = false;
	let webPagePayload = null;
	let showYoutubePlayer = false;
	let youtubePayload = null;
	let showInterviewDigestPlayer = false;
	let interviewDigestPayload = null;

	function onMediaSelectionRequest(media) {
		console.log("media", media);

		if (media._type == "Picture") {
			$$invalidate(1, imageViewerPayload = media);
			$$invalidate(0, showImageViewer = true);
		} else if (media._type == "Audio") {
			$$invalidate(3, soundCloudPayload = media);
			$$invalidate(2, showSoundCloudPlayer = true);
		} else if (media._type == "WebPage") {
			$$invalidate(5, webPagePayload = media);
			$$invalidate(4, showWebPageViewer = true);
		} else if (media._type == "Video") {
			$$invalidate(7, youtubePayload = media);
			$$invalidate(6, showYoutubePlayer = true);
		} else if (media.type == "Interview") {
			$$invalidate(9, interviewDigestPayload = media);
			$$invalidate(8, showInterviewDigestPlayer = true);
		}
	}

	// function updateArrowState(){
	//     if(medias.length == 1){
	//         showNextArrow = false;
	//         showPreviousArrow = false;
	//     }else if (currentMediaIndex == 0){
	//         showPreviousArrow = false;
	//         showNextArrow = true;
	//     }else if(currentMediaIndex == medias.length - 1){
	//         showPreviousArrow = true;
	//         showNextArrow = false;
	//     }else{
	//         showPreviousArrow = true;
	//         showNextArrow = true;
	//     }
	//     console.log("arrow state", showPreviousArrow, showNextArrow);
	// }
	function onNextRequest() {
		$$invalidate(12, currentMediaIndex += 1);
	}

	function onPreviousRequest() {
		$$invalidate(12, currentMediaIndex -= 1);
	}

	const closeRequest_handler = () => $$invalidate(0, showImageViewer = false);
	const closeRequest_handler_1 = () => $$invalidate(2, showSoundCloudPlayer = false);
	const closeRequest_handler_2 = () => $$invalidate(4, showWebPageViewer = false);
	const closeRequest_handler_3 = () => $$invalidate(6, showYoutubePlayer = false);
	const closeRequest_handler_4 = () => $$invalidate(8, showInterviewDigestPlayer = false);

	$$self.$$set = $$props => {
		if ("medias" in $$props) $$invalidate(10, medias = $$props.medias);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*medias, currentMediaIndex, currentMedia*/ 7168) {
			// let showPreviousArrow = false;
			// let showNextArrow = false;
			$: {
				$$invalidate(11, currentMedia = medias[currentMediaIndex]);
				console.log("media count", medias.length);
				console.log("current media updated", currentMedia);
				onMediaSelectionRequest(currentMedia);
			} // updateArrowState();
		}
	};

	return [
		showImageViewer,
		imageViewerPayload,
		showSoundCloudPlayer,
		soundCloudPayload,
		showWebPageViewer,
		webPagePayload,
		showYoutubePlayer,
		youtubePayload,
		showInterviewDigestPlayer,
		interviewDigestPayload,
		medias,
		currentMedia,
		currentMediaIndex,
		closeRequest_handler,
		closeRequest_handler_1,
		closeRequest_handler_2,
		closeRequest_handler_3,
		closeRequest_handler_4
	];
}

class MediaViewer extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { medias: 10 });
	}
}

export default MediaViewer;