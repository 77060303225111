/* ts/svelte/MainApp.svelte generated by Svelte v3.31.2 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	create_component,
	destroy_component,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	safe_not_equal,
	set_data,
	space,
	text,
	transition_in,
	transition_out
} from "svelte/internal";

import { jsToSvelteEventProvider, JsToSvelteEventType } from "./jsToSvelteBus";
import { svelteToJsEventProvider, SvelteToJsEventType } from "./svetleToJsBus";
import { onMount, onDestroy } from "svelte";
import ArrowsScreen from "./ArrowsScreen.svelte";
import MediaViewer from "./MediaViewer.svelte";
import { AudioContext } from "three";

function create_if_block(ctx) {
	let mediaviewer;
	let current;
	mediaviewer = new MediaViewer({ props: { medias: /*medias*/ ctx[4] } });

	return {
		c() {
			create_component(mediaviewer.$$.fragment);
		},
		m(target, anchor) {
			mount_component(mediaviewer, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const mediaviewer_changes = {};
			if (dirty & /*medias*/ 16) mediaviewer_changes.medias = /*medias*/ ctx[4];
			mediaviewer.$set(mediaviewer_changes);
		},
		i(local) {
			if (current) return;
			transition_in(mediaviewer.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(mediaviewer.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(mediaviewer, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div1;
	let a0;
	let t0;
	let div0;
	let t1_value = (/*isMuted*/ ctx[0] ? "unMute" : "mute") + "";
	let t1;
	let t2;
	let div2;
	let t5;
	let arrowsscreen;
	let t6;
	let if_block_anchor;
	let current;
	let mounted;
	let dispose;

	arrowsscreen = new ArrowsScreen({
			props: {
				showNext: /*showNextArrow*/ ctx[1],
				showPrevious: /*showPreviousArrow*/ ctx[2],
				subcat: /*currentSubcat*/ ctx[3]
			}
		});

	arrowsscreen.$on("nextRequest", /*onNextRequest*/ ctx[5]);
	arrowsscreen.$on("previousRequest", /*onPreviousRequest*/ ctx[6]);
	let if_block = /*medias*/ ctx[4].length > 0 && create_if_block(ctx);

	return {
		c() {
			div1 = element("div");
			a0 = element("a");
			a0.innerHTML = `<img src="/images/question.svg" alt="about" class="svelte-hi5kn0"/>`;
			t0 = space();
			div0 = element("div");
			t1 = text(t1_value);
			t2 = space();
			div2 = element("div");
			div2.innerHTML = `le modèle 3D provient de la collection OpenData de l&#39;Etat de Genève <a href="https://ge.ch/sitg/" target="_blank" class="svelte-hi5kn0">[SITG]</a>`;
			t5 = space();
			create_component(arrowsscreen.$$.fragment);
			t6 = space();
			if (if_block) if_block.c();
			if_block_anchor = empty();
			attr(a0, "href", "https://public.cartesetreves.ch/");
			attr(a0, "target", "_blank");
			attr(a0, "class", "svelte-hi5kn0");
			attr(div0, "class", "button no-select svelte-hi5kn0");
			attr(div1, "class", "menu svelte-hi5kn0");
			attr(div2, "class", "attribution svelte-hi5kn0");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, a0);
			append(div1, t0);
			append(div1, div0);
			append(div0, t1);
			insert(target, t2, anchor);
			insert(target, div2, anchor);
			insert(target, t5, anchor);
			mount_component(arrowsscreen, target, anchor);
			insert(target, t6, anchor);
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;

			if (!mounted) {
				dispose = listen(div0, "click", /*unMute*/ ctx[7]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if ((!current || dirty & /*isMuted*/ 1) && t1_value !== (t1_value = (/*isMuted*/ ctx[0] ? "unMute" : "mute") + "")) set_data(t1, t1_value);
			const arrowsscreen_changes = {};
			if (dirty & /*showNextArrow*/ 2) arrowsscreen_changes.showNext = /*showNextArrow*/ ctx[1];
			if (dirty & /*showPreviousArrow*/ 4) arrowsscreen_changes.showPrevious = /*showPreviousArrow*/ ctx[2];
			if (dirty & /*currentSubcat*/ 8) arrowsscreen_changes.subcat = /*currentSubcat*/ ctx[3];
			arrowsscreen.$set(arrowsscreen_changes);

			if (/*medias*/ ctx[4].length > 0) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*medias*/ 16) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(arrowsscreen.$$.fragment, local);
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(arrowsscreen.$$.fragment, local);
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			if (detaching) detach(t2);
			if (detaching) detach(div2);
			if (detaching) detach(t5);
			destroy_component(arrowsscreen, detaching);
			if (detaching) detach(t6);
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let isMuted = AudioContext.getContext().state == "suspended"
	? true
	: false;

	// used to navigate depth of 3d markers
	let showNextArrow = false;

	let showPreviousArrow = false;
	let currentSubcat = "Reves";
	let medias = [];

	onMount(() => {
		console.log("svelte app mounted");
		jsToSvelteEventProvider.addEventListener(JsToSvelteEventType.MediaSelectionRequest, onMediaSelectionRequest);
		jsToSvelteEventProvider.addEventListener(JsToSvelteEventType.ArrowScreenUpdate, onArrowScreenUpdate);
		jsToSvelteEventProvider.addEventListener(JsToSvelteEventType.SubcatUpdate, onSubcatUpdate);
	});

	onDestroy(() => {
		jsToSvelteEventProvider.removeEventListener(JsToSvelteEventType.MediaSelectionRequest, onMediaSelectionRequest);
		jsToSvelteEventProvider.removeEventListener(JsToSvelteEventType.ArrowScreenUpdate, onArrowScreenUpdate);
		jsToSvelteEventProvider.removeEventListener(JsToSvelteEventType.SubcatUpdate, onSubcatUpdate);
	});

	function onMediaSelectionRequest(event) {
		$$invalidate(4, medias = event.value);
	}

	function onArrowScreenUpdate(event) {
		const { hasNext, hasPrevious } = event.value;
		$$invalidate(2, showPreviousArrow = hasPrevious);
		$$invalidate(1, showNextArrow = hasNext);
	}

	function onSubcatUpdate(event) {
		$$invalidate(3, currentSubcat = event.value.subcat);
	}

	function onNextRequest() {
		svelteToJsEventProvider.provide(SvelteToJsEventType.MediaListUpdateRequest, { next: true, previous: false });
	}

	function onPreviousRequest() {
		svelteToJsEventProvider.provide(SvelteToJsEventType.MediaListUpdateRequest, { next: false, previous: true });
	}

	function unMute() {
		$$invalidate(0, isMuted = !isMuted);

		if (isMuted) {
			AudioContext.getContext().suspend().then(() => {
				console.log("Playback suspended successfully");
			});
		} else {
			AudioContext.getContext().resume().then(() => {
				console.log("Playback resumed successfully");
			});
		}
	}

	return [
		isMuted,
		showNextArrow,
		showPreviousArrow,
		currentSubcat,
		medias,
		onNextRequest,
		onPreviousRequest,
		unMute
	];
}

class MainApp extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default MainApp;