import { Coordinates } from "../model/CommonTypes";
import {
    AudioListener,
    AudioLoader, 
    Object3D, 
    PositionalAudio,
} from 'three';
import { latLonToXY } from "./geo_utils";

export class SpatialAudio{

    private positionalAudio!: PositionalAudio;
    private _root: Object3D = new Object3D();

    constructor(
        private filename: string, 
        private coordinates: Coordinates, 
        private refDistance: number,
        private rollofFactor: number,
        audioListener: AudioListener,
    ){
        this.positionalAudio = new PositionalAudio(audioListener);

        const coords = latLonToXY(this.coordinates);

        this._root.position.set(coords.x, 20, coords.y);
        this._root.add(this.positionalAudio);

    }

    public load(){
        const audioloader = new AudioLoader();
        const filePath = `/audio/${this.filename}`;

        audioloader.load(filePath, (buffer)  => {
            console.log(`loaded audio buffer ${filePath}`);
            this.positionalAudio.setBuffer(buffer);
            this.positionalAudio.setLoop(true);
            this.positionalAudio.setRefDistance(this.refDistance);
            this.positionalAudio.setRolloffFactor(this.rollofFactor);
            this.positionalAudio.play();
        });
    }

    public get root(): Object3D{
        return this._root;
    }
}