/* ts/svelte/ImageViewer.svelte generated by Svelte v3.31.2 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	bubble,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	listen,
	mount_component,
	safe_not_equal,
	set_data,
	space,
	text,
	transition_in,
	transition_out
} from "svelte/internal";

import Modal from "./Modal.svelte";
import { db } from "../model/Db";
import { onMount, onDestroy } from "svelte";

function create_if_block(ctx) {
	let p;
	let t0;
	let t1;

	return {
		c() {
			p = element("p");
			t0 = text("Description: ");
			t1 = text(/*description*/ ctx[5]);
		},
		m(target, anchor) {
			insert(target, p, anchor);
			append(p, t0);
			append(p, t1);
		},
		p(ctx, dirty) {
			if (dirty & /*description*/ 32) set_data(t1, /*description*/ ctx[5]);
		},
		d(detaching) {
			if (detaching) detach(p);
		}
	};
}

// (32:4) <div class="container" slot="content">
function create_content_slot(ctx) {
	let div0;
	let img;
	let img_src_value;
	let t0;
	let div1;
	let h2;
	let t1;
	let t2;
	let p;
	let t3;
	let t4;
	let t5;
	let mounted;
	let dispose;
	let if_block = /*description*/ ctx[5] && create_if_block(ctx);

	return {
		c() {
			div0 = element("div");
			img = element("img");
			t0 = space();
			div1 = element("div");
			h2 = element("h2");
			t1 = text(/*title*/ ctx[4]);
			t2 = space();
			p = element("p");
			t3 = text("Auteur.e: ");
			t4 = text(/*userName*/ ctx[3]);
			t5 = space();
			if (if_block) if_block.c();
			if (img.src !== (img_src_value = /*imageUrl*/ ctx[2])) attr(img, "src", img_src_value);
			attr(img, "alt", "");
			attr(img, "class", "svelte-1n1ey7l");
			attr(h2, "class", "title");
			attr(div1, "class", "text svelte-1n1ey7l");
			attr(div0, "class", "container svelte-1n1ey7l");
			attr(div0, "slot", "content");
		},
		m(target, anchor) {
			insert(target, div0, anchor);
			append(div0, img);
			/*img_binding*/ ctx[8](img);
			append(div0, t0);
			append(div0, div1);
			append(div1, h2);
			append(h2, t1);
			append(div1, t2);
			append(div1, p);
			append(p, t3);
			append(p, t4);
			append(div1, t5);
			if (if_block) if_block.m(div1, null);

			if (!mounted) {
				dispose = listen(img, "load", /*onImgLoaded*/ ctx[6]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*imageUrl*/ 4 && img.src !== (img_src_value = /*imageUrl*/ ctx[2])) {
				attr(img, "src", img_src_value);
			}

			if (dirty & /*title*/ 16) set_data(t1, /*title*/ ctx[4]);
			if (dirty & /*userName*/ 8) set_data(t4, /*userName*/ ctx[3]);

			if (/*description*/ ctx[5]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div1, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d(detaching) {
			if (detaching) detach(div0);
			/*img_binding*/ ctx[8](null);
			if (if_block) if_block.d();
			mounted = false;
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let modal;
	let current;

	modal = new Modal({
			props: {
				isOpen: /*isOpen*/ ctx[0],
				noInteract: true,
				$$slots: { content: [create_content_slot] },
				$$scope: { ctx }
			}
		});

	modal.$on("closeRequest", /*closeRequest_handler*/ ctx[9]);

	return {
		c() {
			create_component(modal.$$.fragment);
		},
		m(target, anchor) {
			mount_component(modal, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const modal_changes = {};
			if (dirty & /*isOpen*/ 1) modal_changes.isOpen = /*isOpen*/ ctx[0];

			if (dirty & /*$$scope, description, userName, title, imageUrl, imgElement*/ 1086) {
				modal_changes.$$scope = { dirty, ctx };
			}

			modal.$set(modal_changes);
		},
		i(local) {
			if (current) return;
			transition_in(modal.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(modal.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(modal, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let imageUrl;
	let userName;
	let title;
	let description;
	let { isOpen = false } = $$props;
	let { payload = null } = $$props;
	let imgElement;
	if (payload) console.log("payload data", payload._data);

	function onImgLoaded() {
		console.log(imgElement.width, imgElement.height, "loaded");
	} // if(imgElement.width > imgElement.height){
	//     isLandscape = true;

	function img_binding($$value) {
		binding_callbacks[$$value ? "unshift" : "push"](() => {
			imgElement = $$value;
			$$invalidate(1, imgElement);
		});
	}

	function closeRequest_handler(event) {
		bubble($$self, event);
	}

	$$self.$$set = $$props => {
		if ("isOpen" in $$props) $$invalidate(0, isOpen = $$props.isOpen);
		if ("payload" in $$props) $$invalidate(7, payload = $$props.payload);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*payload*/ 128) {
			// let isLandscape = false;
			$: $$invalidate(2, imageUrl = payload ? payload._data.MediaFile[0].url : "");
		}

		if ($$self.$$.dirty & /*payload*/ 128) {
			$: $$invalidate(3, userName = payload
			? db.getContributorWithId(payload._data.CreatedBy[0]).FullNameContributor
			: "");
		}

		if ($$self.$$.dirty & /*payload*/ 128) {
			$: $$invalidate(4, title = payload ? payload._data.TitleOfMedia : "");
		}

		if ($$self.$$.dirty & /*payload*/ 128) {
			$: $$invalidate(5, description = payload && payload._data.Description
			? payload._data.Description
			: "");
		}
	};

	return [
		isOpen,
		imgElement,
		imageUrl,
		userName,
		title,
		description,
		onImgLoaded,
		payload,
		img_binding,
		closeRequest_handler
	];
}

class ImageViewer extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { isOpen: 0, payload: 7 });
	}
}

export default ImageViewer;