/* ts/svelte/TweetApp.svelte generated by Svelte v3.31.2 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	safe_not_equal,
	set_data,
	set_style,
	space,
	text,
	transition_in,
	transition_out
} from "svelte/internal";

import TweetUi from "./TweetUI.svelte";
import { jsToSvelteEventProvider, JsToSvelteEventType } from "./jsToSvelteBus";
import { onMount, onDestroy } from "svelte";

function create_fragment(ctx) {
	let div;
	let h2;
	let t0_value = /*titles*/ ctx[3][/*currentSubcat*/ ctx[1]].title + "";
	let t0;
	let t1;
	let p;
	let t2_value = /*titles*/ ctx[3][/*currentSubcat*/ ctx[1]].description + "";
	let t2;
	let t3;
	let tweetui;
	let current;

	tweetui = new TweetUi({
			props: {
				tweets: /*currentTweets*/ ctx[0],
				currentSubcat: /*currentSubcat*/ ctx[1]
			}
		});

	return {
		c() {
			div = element("div");
			h2 = element("h2");
			t0 = text(t0_value);
			t1 = space();
			p = element("p");
			t2 = text(t2_value);
			t3 = space();
			create_component(tweetui.$$.fragment);
			set_style(h2, "color", /*subcatColor*/ ctx[2]);
			attr(h2, "class", "svelte-1pya0hs");
			attr(p, "class", "svelte-1pya0hs");
			attr(div, "class", "header svelte-1pya0hs");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, h2);
			append(h2, t0);
			append(div, t1);
			append(div, p);
			append(p, t2);
			insert(target, t3, anchor);
			mount_component(tweetui, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			if ((!current || dirty & /*currentSubcat*/ 2) && t0_value !== (t0_value = /*titles*/ ctx[3][/*currentSubcat*/ ctx[1]].title + "")) set_data(t0, t0_value);

			if (!current || dirty & /*subcatColor*/ 4) {
				set_style(h2, "color", /*subcatColor*/ ctx[2]);
			}

			if ((!current || dirty & /*currentSubcat*/ 2) && t2_value !== (t2_value = /*titles*/ ctx[3][/*currentSubcat*/ ctx[1]].description + "")) set_data(t2, t2_value);
			const tweetui_changes = {};
			if (dirty & /*currentTweets*/ 1) tweetui_changes.tweets = /*currentTweets*/ ctx[0];
			if (dirty & /*currentSubcat*/ 2) tweetui_changes.currentSubcat = /*currentSubcat*/ ctx[1];
			tweetui.$set(tweetui_changes);
		},
		i(local) {
			if (current) return;
			transition_in(tweetui.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(tweetui.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (detaching) detach(t3);
			destroy_component(tweetui, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let tweetMap = null;
	let currentTweets = [];
	let currentSubcat = "Reves";
	let subcatColor = "#ffd932";

	const titles = {
		"Reves": {
			title: "Rêves",
			description: "Racontez-nous un rêve que vous avez pour le quartier..."
		},
		"Nuisance": {
			title: "Nuisances",
			description: "Est-ce que les nuisances sonores vous dérangent dans votre quotidien? Pourquoi?"
		},
		"Quotidien": {
			title: "Quotidien",
			description: "Selon vous, que manque-t-il dans le quartier?"
		},
		"Archives": { title: "Archives", description: "" },
		"Insecurite": {
			title: "Insécurité",
			description: "A quel point vous sentez-vous en sécurité dans ce quartier?"
		}
	};

	onMount(() => {
		console.log("svelte tweet app mounted");
		jsToSvelteEventProvider.addEventListener(JsToSvelteEventType.TweetCollectionUpdate, onTweetCollectionUpdate);
		jsToSvelteEventProvider.addEventListener(JsToSvelteEventType.SubcatUpdate, onSubcatUpdate);
		jsToSvelteEventProvider.addEventListener(JsToSvelteEventType.SubcatColorChange, onSubcatColorChange);
	});

	onDestroy(() => {
		jsToSvelteEventProvider.removeEventListener(JsToSvelteEventType.TweetCollectionUpdate, onTweetCollectionUpdate);
		jsToSvelteEventProvider.removeEventListener(JsToSvelteEventType.SubcatUpdate, onSubcatUpdate);
		jsToSvelteEventProvider.removeEventListener(JsToSvelteEventType.SubcatColorChange, onSubcatColorChange);
	});

	function onSubcatColorChange(e) {
		$$invalidate(2, subcatColor = e.value.color);
	}

	function onSubcatUpdate(event) {
		console.log("on subcat update in tweet app", event);
		$$invalidate(1, currentSubcat = event.value.subcat);

		if (tweetMap) {
			$$invalidate(0, currentTweets = tweetMap.get(currentSubcat));
			console.log("currentTweets", currentTweets);
		}
	}

	function onTweetCollectionUpdate(event) {
		tweetMap = event.value.tweetMap;
		console.log("on tweet udpate", tweetMap);
		$$invalidate(0, currentTweets = tweetMap.get(currentSubcat));
	}

	return [currentTweets, currentSubcat, subcatColor, titles];
}

class TweetApp extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default TweetApp;