import { Id, Subcategory, Tag } from "../model/CommonTypes";
import { Vector3 } from "three";
import { Coordinates, Place } from "./CommonTypes";

import { db } from './Db';
import { randomItemFromArray } from "../utils";

import Media from '../model/Media';
import { SubcategoryOrderList, SubcategoryType, subcatInfoForSubcatType, subcatTypeForSubCatId } from "./SubcategoryHelpers";

export class SmartPlace
{

    private _tags: Tag[] = [];

    constructor(private _place: Place, private _thematicSubcategoryIds: Id[]){
        this.generateTags();
    }

    public isShowableOnMap(): boolean{
        return this.tags.length > 0;
    }

    public get id(): Id{
        return this._place.id;
    }

    public get coordinates(): Coordinates{
        return this._place.coordinates;
    }

    public get position(): Vector3{
        return this._place.position;
    }

    public get containsNoTags():boolean{
        return this._place.labels.length + this._place.subcategories.length == 0;
    }

    public get tags(): Tag[]{
        return this._tags;
    }
    
    firstMediaForSubcat(subcatType: SubcategoryType): Media {
        return this.mediasForSubcatType(subcatType)[0];
    }
   
    mediasForSubcatType(subcatType: SubcategoryType): Media[] {
        const tag = this.tags.find((t:Tag) => t.subcatType == subcatType) as Tag;
        return tag.media.map((mediaId:Id) => db.getMediaWithId(mediaId) as Media);
    }

    public containsTag(tag: Tag): boolean{
        return !!this.tags.find((t: Tag) => t == tag );
    }

    public containsTagOfType(subcatType: SubcategoryType): boolean{
        return !!this.tags.find((t: Tag) => t.subcatType == subcatType);
    }

    public closestMediaForSubcatType(subcatType: SubcategoryType): Media[]{
        const tag = this.tags.find((t:Tag) => t.subcatType == subcatType) as Tag;
        return this.closestMediaForTag(tag);
    }

    public closestMediaForTag(tag: Tag): Media[]{
        let media = tag.media.map(id => db.getMediaWithId(id)) as Media[];

        media.sort((a:Media, b:Media) => {
            return a.position.distanceTo(this.position) - b.position.distanceTo(this.position);
        });

        return media;
    }

    public randomMediaForTag(tag: Tag): Media | null{
        if(!this.containsTag(tag)){
            return null;
        }

        if(tag.media.length == 0){
            return null;
        }

        const mediaId = randomItemFromArray(tag.media);

        return db.getMediaWithId(mediaId);
    } 

    public get randomTag(): Tag | null{
        return randomItemFromArray(this.tags);
    }

    private generateTags(){

        for(const subcatType of SubcategoryOrderList){
            const info = subcatInfoForSubcatType(subcatType);

            const medias = this._place.media.filter((mediaId) =>{
                const media = db.getMediaWithId(mediaId); 
                if(!media){
                    return false;
                }
                return db.mediaIsOfSubcatType(media, subcatType);
            });
            
            if(medias.length > 0){
                const tag: Tag = {
                    name: info.displayName,
                    id: info.id,
                    subcatType: subcatType,
                    media: medias,
                };
                this._tags.push(tag);
            }
        }

        // this._place.subcategories.forEach((subcategoryId: Id) => {
        //     const subCategory: Subcategory | null = db.findSubcategoryWithId(subcategoryId);
        //     if(subCategory && this._thematicSubcategoryIds.includes(subCategory.id)){
        //         const subcatType = subcatTypeForSubCatId(subCategory.id);
        //         if(!subcatType){
        //             console.log("sub", subCategory);
        //             throw new Error(`no subcatType found for id ${subCategory.id}`);
        //         }
        //         const tag: Tag = {
        //             name: subCategory.NameOfSubcategory,
        //             id: subCategory.id,
        //             subcatType: subcatType, 
        //             media: subCategory.Media,
        //         };
        //         this._tags.push(tag);
        //     }
        // });
    }
}