import { BaseEventProvider } from "../common/BaseEventProvider";

export class JsToSvelteEventProvider extends BaseEventProvider
{
    constructor(){
        super();
    }
}

export const jsToSvelteEventProvider = new JsToSvelteEventProvider();

export const JsToSvelteEventType = {
    MediaSelectionRequest: "MediaSelectionRequest",
    ArrowScreenUpdate: "ArrowScreenUpdate",
    SubcatColorChange: "SubcatColorChange",
    TweetCollectionUpdate: "TweetCollectionUpdate",
    SubcatUpdate: "SubcatUpdate",
};