import SubCatLabel from "./SubCatLabel";
import  { 
    SubcategoryOrderList, 
    subcatInfoForSubcat,
    subcatInfoForSubcatType,
} from '../model/SubcategoryHelpers';

export class SubcatLabelManager
{

    private _labels:SubCatLabel[] = [];

    constructor() {
        for(const subcatType of SubcategoryOrderList){
            const info = subcatInfoForSubcatType(subcatType);
            console.log("info", info);

            this._labels.push(new SubCatLabel(info));
        }
    }

    public get labels(): SubCatLabel[]{
        return this._labels;
    }

    public unselectAllLabels(){
        this._labels.forEach((l: SubCatLabel) => l.unSelect());
    }
}