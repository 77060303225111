import { db } from '../model/Db'

import { BaseEventProvider } from '../common/BaseEventProvider';

import {
    Vector3,
} from 'three';

import { MyEvent, Listener } from '../common/BaseEventProvider';
import { Context } from '../common/InteractionEventProvider';
import SubCatLabel from './SubCatLabel';
import FloatingImage, { FloatingImageEventType } from './FloatingImage';
import { SmartPlace } from '../model/SmartPlace';
import { Tag, Id } from '../model/CommonTypes';
import Media from '../model/Media';
import PlaceAndMediaList from '../model/PlacesAndMediaList';

import { jsToSvelteEventProvider, JsToSvelteEventType } from '../svelte/jsToSvelteBus';
import { svelteToJsEventProvider, SvelteToJsEventType } from '../svelte/svetleToJsBus';
import { SubcatLabelManager } from './SubcatLabelManager';
import { SubcategoryType, SubCatInfo, subcatInfoForSubcatType } from '../model/SubcategoryHelpers';
import { PlaceAndMedia } from '../model/PlaceAndMedia';

export const MediaMarkerEventType = {
    select: 'select',
    unselect: 'unselect',
};

export class PlacesManager extends BaseEventProvider
{
    private interactionHandler!: Listener;

    private floatingImages: FloatingImage[] = [];

    private places!: SmartPlace[];
    private oldPlaces: SmartPlace[] | null = null;
    private currentTags: Tag[] = [];

    private selectedLabelId: Id | null = null;
    private labelClickHandler!: (e: MyEvent) => void;
    private floatingImageClickHandler!: (e: MyEvent) => void;

    private selectedLabelHasChanged: boolean = false;

    private mediaList: PlaceAndMediaList = new PlaceAndMediaList([]);

    static readonly maxVisiblePlaceCount = 4;

    private labelManager!: SubcatLabelManager;

    private currentSubcategory: SubCatInfo = subcatInfoForSubcatType(SubcategoryType.Reves);

    constructor(private context: Context){
        super();

        this.places = db.placesForSubcat(this.currentSubcategory.type);
        this.oldPlaces = null;

        this.labelClickHandler = this.onLabelSelectionRequest.bind(this);
        this.floatingImageClickHandler = this.onFloatingImageSelectionRequest.bind(this);

        this.labelManager = new SubcatLabelManager();

        for(const label of this.labelManager.labels){
            label.addEventListener("selectionRequest", this.labelClickHandler);
        }

        for(let i = 0; i < PlacesManager.maxVisiblePlaceCount; i++){

            const floatingImage = new FloatingImage();
            floatingImage.scaleMultiplier = 170;
            floatingImage.activate();
            floatingImage.addEventListener(FloatingImageEventType.click, this.floatingImageClickHandler);
            floatingImage.addToScene(context.scene);

            floatingImage.color = this.currentSubcategory.color;
        
            this.floatingImages.push(floatingImage);
        }

        svelteToJsEventProvider.addEventListener(SvelteToJsEventType.MediaListUpdateRequest, this.onMediaListUpdateRequest.bind(this));

        setTimeout(() => {
            jsToSvelteEventProvider.provide(JsToSvelteEventType.SubcatColorChange, {color: this.currentSubcategory.color});
            jsToSvelteEventProvider.provide(JsToSvelteEventType.SubcatUpdate, {subcat: this.currentSubcategory.type});
        }, 2000);
    }

    private onLabelSelectionRequest(e: MyEvent){

        const payload: SubCatInfo = e.value.subcatInfo;
        console.log("onLabelSelectionRequest", payload);

        if(this.currentSubcategory == payload){
            return;
        }
        
        this.currentSubcategory = payload;

        for(const floatingImage of this.floatingImages){
            floatingImage.color = this.currentSubcategory.color;
        }

        this.places = db.placesForSubcat(this.currentSubcategory.type);
        this.oldPlaces = null;

        this.selectedLabelHasChanged = true;
        jsToSvelteEventProvider.provide(JsToSvelteEventType.SubcatColorChange, {color: this.currentSubcategory.color});
        jsToSvelteEventProvider.provide(JsToSvelteEventType.SubcatUpdate, {subcat: this.currentSubcategory.type});
    }

    private onFloatingImageSelectionRequest(e: MyEvent){
        console.log("got image click", e.value);
        const media: Media = e.value.media as Media;
        const place: SmartPlace = e.value.place as SmartPlace;
        console.log("got media", media);
        console.log("got place", place);

        const mediasForPlace: Media[] = place.mediasForSubcatType(this.currentSubcategory.type);
        jsToSvelteEventProvider.provide(JsToSvelteEventType.MediaSelectionRequest, mediasForPlace);
    }

    private findClosestPlaces(places: SmartPlace[]): SmartPlace[]{
        const cameraPosition: Vector3 = this.context.camera.position;

        // sort closest Places to camera.
        return places.sort((a: SmartPlace, b: SmartPlace) => {
            return a.position.distanceTo(cameraPosition) - b.position.distanceTo(cameraPosition);
        });
    }

    private requiresRecomputing(newSortedPlaces: SmartPlace[]): boolean{
        if(!this.oldPlaces) return true;

        for(let i = 0; i < PlacesManager.maxVisiblePlaceCount; i++){
            if(newSortedPlaces[i].id != this.oldPlaces[i].id){
                return true;
            }
        }

        return false;
    }

    // recomputeCurrentTags(newSortedPlaces: SmartPlace[]){
    //     const tagNameSet = new Set<string>();
    //     const expectedTagCount = PlacesManager.maxVisiblePlaceCount;
    //     let tagCounter = 0;

    //     for(let i = 0; i < newSortedPlaces.length; i++){
    //         const nextTag = newSortedPlaces[i].randomTag as Tag;
    //         if(!tagNameSet.has(nextTag.name)){
    //             this.currentTags[tagCounter++] = nextTag;
    //             if(tagCounter == expectedTagCount){
    //                 // all done
    //                 break;
    //             }
    //             tagNameSet.add(nextTag.name);
    //         }
    //         // this.currentTags[i] = newSortedPlaces[i].randomTag as Tag;
    //     }
    // }

    onMediaListUpdateRequest(event:any){
        console.log("ping", event.value);

        if(event.value.next){
            this.mediaList.next();
        }

        if(event.value.previous){
            this.mediaList.previous();
        }
        this.updateMediaListDisplay();
    }

    updateMediaList(newSortedPlaces: SmartPlace[]){
        const placesAndMedia: PlaceAndMedia[] = newSortedPlaces.map((place: SmartPlace) =>{
            const media: Media = place.firstMediaForSubcat(this.currentSubcategory.type);

            const result ={
                media: media,
                place: place,
            } 
            return result;
        });
        this.mediaList = new PlaceAndMediaList(placesAndMedia);
    }

    updateMediaListDisplay(){
        const window:PlaceAndMedia[] = this.mediaList.getWindow();

        for(let i = 0; i < PlacesManager.maxVisiblePlaceCount; i++){
            // TODO handle case where fewer than 4 media are available
            if(i < window.length){
                this.floatingImages[i].loadMedia(window[i].media, window[i].place);
                this.floatingImages[i].show();
            }else{
                // we don't have enough media for our 4 images so we hide remaining images
                this.floatingImages[i].hide();
            }
        }

        jsToSvelteEventProvider.provide(JsToSvelteEventType.ArrowScreenUpdate,  {
            hasNext: this.mediaList.hasNext(),
            hasPrevious: this.mediaList.hasPrevious(),
        });
    }

    private computeImageHeight(): number{

        const lerp = (value: number, min: number, max: number) => {
            value = value < 0 ? 0 : value;
            value = value > 1 ? 1 : value;
            return min + (max - min) * value;
        }

        const camToCenter = this.context.camera.position.clone();
        const distance = camToCenter.length();

        const minCamDistance = 799;
        const maxCamDistance = 3000;

        const distanceNormalized = (distance - minCamDistance) / (maxCamDistance - minCamDistance);

        const maxDistanceHeight = 400;
        const minDistanceHeight = 220;

        return lerp(distanceNormalized, minDistanceHeight, maxDistanceHeight);
    }

    public update(){

        const newSortedPlaces = this.findClosestPlaces(this.places);
        // we only update if sorted place order has changed (camera movement triggers a new set of closest places)
        if(this.requiresRecomputing(newSortedPlaces)){
            // console.log("----------------------------------------recomputing tags");
            // this.recomputeCurrentTags(newSortedPlaces);
            this.updateMediaList(newSortedPlaces);
            this.updateMediaListDisplay();
            this.oldPlaces = newSortedPlaces.slice();
        }

        const maxFloatingImages = 4;

        for(let i = 0; i < maxFloatingImages; i++){
            const place: SmartPlace = newSortedPlaces[i];
    
            // update floating image positions
            {
                const camToCenter = this.context.camera.position.clone();
                camToCenter.y = 0;
                camToCenter.normalize();

                const upVector = new Vector3(0, 1, 0);

                // this is the axes we want to align our images on
                // TODO: compute this only once!
                const perpVector = camToCenter.cross(upVector);

                const scaleFactor = 200;
                const length =  maxFloatingImages * scaleFactor;

                const perpOrigin = new Vector3(0, 0, 0).add(perpVector.clone().multiplyScalar(length / 2));
                // console.log("perp origin", perpOrigin);

                const lengthVector = perpVector.clone().multiplyScalar(length);
                const ratio = i / (maxFloatingImages - 1);

                const imagePos = perpOrigin.clone().sub(lengthVector.clone().multiplyScalar(ratio));

                const imageHeight = this.computeImageHeight();
                // imagePos.y = 220;
                imagePos.y = imageHeight;
                // console.log("pos", i, ratio, perpOrigin);
                // console.log("length vector", i, length, lengthVector, lengthVector.length());

                this.floatingImages[i].position = imagePos;
                this.floatingImages[i].update(this.context.camera.position);
            }
        }
    }
}