import { Id } from '../model/CommonTypes';
import { Subcategory } from "./CommonTypes";

export interface SubCatInfo{
    id: Id,
    type: SubcategoryType,
    icon: string,
    displayName: string,
    color: string,
};

export enum SubcategoryType {
    Insecurite = "Insecurite",
    Nuisance = "Nuisance",
    Reves = "Reves",
    Archives = "Archives",
    Quotidien = "Quotidien",
};

export const SubcategoryOrderList = [
    SubcategoryType.Reves,
    SubcategoryType.Quotidien,
    SubcategoryType.Archives,
    SubcategoryType.Nuisance,
    SubcategoryType.Insecurite,
];

const subcatIdToNameMap = {
    "rec2U2sM5Ie4Vilro": SubcategoryType.Insecurite,
    "recEEsWP8JG20ywd5": SubcategoryType.Nuisance,
    "recpu5dkMJQInOdWf": SubcategoryType.Reves,
    "reciZYc82AB6zDhOK": SubcategoryType.Archives,
    "recEx0GBNYzt5EU4Z": SubcategoryType.Quotidien,
};

const subcatMap = {
    "Insecurite":{
        id: "rec2U2sM5Ie4Vilro",
        type: SubcategoryType.Insecurite,
        icon: "/images/_icons/Insecurite.png",
        displayName: "Insécurité",
        color: "#004d80",
    },
    "Nuisance":{
        id: "recEEsWP8JG20ywd5",
        type: SubcategoryType.Nuisance,
        icon: "/images/_icons/Nuisances.png",
        displayName: "Nuisances",
        color: "#ff644e"
    },
    "Reves":{
        id: "recpu5dkMJQInOdWf",
        type: SubcategoryType.Reves,
        icon: "/images/_icons/Reves.png",
        displayName: "Rêves",
        color: "#ffd932"
    },
    "Archives":{
        id: "reciZYc82AB6zDhOK",
        type: SubcategoryType.Archives,
        icon: "/images/_icons/Archives.png",
        displayName: "Archives",
        color: "#929292"
    },
    "Quotidien":{
        id: "recEx0GBNYzt5EU4Z",
        type: SubcategoryType.Quotidien,
        icon: "/images/_icons/Quotidien.png",
        displayName: "Quotidien",
        color: "#61d836"
    }
};

export function subcatInfoForSubcat(subcat: Subcategory): SubCatInfo{
    if(!subcat){
        throw new Error("subcategory: Subcategory can not be null");
    }
    const subcatType: SubcategoryType = subcatIdToNameMap[subcat.id as keyof typeof subcatIdToNameMap];
    if(!subcatType){
        throw new Error(`unknown subcat type ${subcat.id}`);
    }
    return subcatMap[subcatType.toString() as keyof typeof subcatMap];
}

export function subcatInfoForSubcatType(subcatType: SubcategoryType): SubCatInfo{
    return subcatMap[subcatType.toString() as keyof typeof subcatMap];
}

export function subcatTypeForSubCatId(subcatId: Id): SubcategoryType{
    return subcatIdToNameMap[subcatId as keyof typeof subcatIdToNameMap];
}