/* ts/svelte/SoundCloudPlayer.svelte generated by Svelte v3.31.2 */
import {
	SvelteComponent,
	attr,
	bubble,
	check_outros,
	create_component,
	destroy_component,
	detach,
	element,
	group_outros,
	init,
	insert,
	mount_component,
	safe_not_equal,
	transition_in,
	transition_out
} from "svelte/internal";

import Modal from "./Modal.svelte";
import SoundCloud from "./SoundCloud.svelte";

function create_if_block(ctx) {
	let soundcloud;
	let current;
	soundcloud = new SoundCloud({ props: { clipUrl: /*clipUrl*/ ctx[1] } });

	return {
		c() {
			create_component(soundcloud.$$.fragment);
		},
		m(target, anchor) {
			mount_component(soundcloud, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const soundcloud_changes = {};
			if (dirty & /*clipUrl*/ 2) soundcloud_changes.clipUrl = /*clipUrl*/ ctx[1];
			soundcloud.$set(soundcloud_changes);
		},
		i(local) {
			if (current) return;
			transition_in(soundcloud.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(soundcloud.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(soundcloud, detaching);
		}
	};
}

// (20:4) <div class="container" slot="content">
function create_content_slot(ctx) {
	let div;
	let current;
	let if_block = /*clipUrl*/ ctx[1] && create_if_block(ctx);

	return {
		c() {
			div = element("div");
			if (if_block) if_block.c();
			attr(div, "class", "container svelte-1yuc43r");
			attr(div, "slot", "content");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			if (if_block) if_block.m(div, null);
			current = true;
		},
		p(ctx, dirty) {
			if (/*clipUrl*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*clipUrl*/ 2) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(div, null);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (if_block) if_block.d();
		}
	};
}

function create_fragment(ctx) {
	let modal;
	let current;

	modal = new Modal({
			props: {
				isOpen: /*isOpen*/ ctx[0],
				noInteract: false,
				$$slots: { content: [create_content_slot] },
				$$scope: { ctx }
			}
		});

	modal.$on("closeRequest", /*closeRequest_handler*/ ctx[3]);

	return {
		c() {
			create_component(modal.$$.fragment);
		},
		m(target, anchor) {
			mount_component(modal, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const modal_changes = {};
			if (dirty & /*isOpen*/ 1) modal_changes.isOpen = /*isOpen*/ ctx[0];

			if (dirty & /*$$scope, clipUrl*/ 18) {
				modal_changes.$$scope = { dirty, ctx };
			}

			modal.$set(modal_changes);
		},
		i(local) {
			if (current) return;
			transition_in(modal.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(modal.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(modal, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let clipUrl;
	let { isOpen = false } = $$props;
	let { payload = null } = $$props;

	function closeRequest_handler(event) {
		bubble($$self, event);
	}

	$$self.$$set = $$props => {
		if ("isOpen" in $$props) $$invalidate(0, isOpen = $$props.isOpen);
		if ("payload" in $$props) $$invalidate(2, payload = $$props.payload);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*payload*/ 4) {
			$: $$invalidate(1, clipUrl = payload ? payload._data.UrlOfMedia : "");
		}
	};

	return [isOpen, clipUrl, payload, closeRequest_handler];
}

class SoundCloudPlayer extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { isOpen: 0, payload: 2 });
	}
}

export default SoundCloudPlayer;