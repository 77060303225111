import { BaseEventProvider } from "../common/BaseEventProvider";

export class SvelteToJsEventProvider extends BaseEventProvider
{
    constructor(){
        super();
    }
}

export const svelteToJsEventProvider = new SvelteToJsEventProvider();

export const SvelteToJsEventType = {
    MediaListUpdateRequest: "MediaListUpdateRequest", // either next or previous
};