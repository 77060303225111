/* ts/svelte/WebPageViewer.svelte generated by Svelte v3.31.2 */
import {
	SvelteComponent,
	append,
	attr,
	bubble,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	safe_not_equal,
	transition_in,
	transition_out
} from "svelte/internal";

import Modal from "./Modal.svelte";
import SoundCloud from "./SoundCloud.svelte";

function create_content_slot(ctx) {
	let div;
	let iframe;
	let iframe_src_value;

	return {
		c() {
			div = element("div");
			iframe = element("iframe");
			attr(iframe, "title", "webpahe");
			attr(iframe, "width", "100%");
			attr(iframe, "height", "100%");
			attr(iframe, "allow", "autoplay");
			if (iframe.src !== (iframe_src_value = /*url*/ ctx[1])) attr(iframe, "src", iframe_src_value);
			attr(iframe, "class", "svelte-q66olo");
			attr(div, "class", "container svelte-q66olo");
			attr(div, "slot", "content");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, iframe);
		},
		p(ctx, dirty) {
			if (dirty & /*url*/ 2 && iframe.src !== (iframe_src_value = /*url*/ ctx[1])) {
				attr(iframe, "src", iframe_src_value);
			}
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

function create_fragment(ctx) {
	let modal;
	let current;

	modal = new Modal({
			props: {
				isOpen: /*isOpen*/ ctx[0],
				noInteract: false,
				$$slots: { content: [create_content_slot] },
				$$scope: { ctx }
			}
		});

	modal.$on("closeRequest", /*closeRequest_handler*/ ctx[3]);

	return {
		c() {
			create_component(modal.$$.fragment);
		},
		m(target, anchor) {
			mount_component(modal, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const modal_changes = {};
			if (dirty & /*isOpen*/ 1) modal_changes.isOpen = /*isOpen*/ ctx[0];

			if (dirty & /*$$scope, url*/ 18) {
				modal_changes.$$scope = { dirty, ctx };
			}

			modal.$set(modal_changes);
		},
		i(local) {
			if (current) return;
			transition_in(modal.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(modal.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(modal, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let url;
	let { isOpen = false } = $$props;
	let { payload = null } = $$props;

	function closeRequest_handler(event) {
		bubble($$self, event);
	}

	$$self.$$set = $$props => {
		if ("isOpen" in $$props) $$invalidate(0, isOpen = $$props.isOpen);
		if ("payload" in $$props) $$invalidate(2, payload = $$props.payload);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*payload*/ 4) {
			$: $$invalidate(1, url = payload ? payload._data.UrlOfMedia : "");
		}
	};

	return [isOpen, url, payload, closeRequest_handler];
}

class WebPageViewer extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { isOpen: 0, payload: 2 });
	}
}

export default WebPageViewer;