/* ts/svelte/ArrowsScreen.svelte generated by Svelte v3.31.2 */
import {
	SvelteComponent,
	attr,
	detach,
	element,
	empty,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	space
} from "svelte/internal";

import { createEventDispatcher } from "svelte";

function create_if_block_1(ctx) {
	let img;
	let img_src_value;
	let mounted;
	let dispose;

	return {
		c() {
			img = element("img");
			attr(img, "alt", "arrow Next");
			if (img.src !== (img_src_value = "/images/_arrows/arrow_" + /*subcat*/ ctx[2] + ".png")) attr(img, "src", img_src_value);
			attr(img, "class", "arrow next no-select svelte-1lkt4c0");
		},
		m(target, anchor) {
			insert(target, img, anchor);

			if (!mounted) {
				dispose = listen(img, "click", /*onNextClick*/ ctx[4]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*subcat*/ 4 && img.src !== (img_src_value = "/images/_arrows/arrow_" + /*subcat*/ ctx[2] + ".png")) {
				attr(img, "src", img_src_value);
			}
		},
		d(detaching) {
			if (detaching) detach(img);
			mounted = false;
			dispose();
		}
	};
}

// (29:0) {#if showPrevious}
function create_if_block(ctx) {
	let img;
	let img_src_value;
	let mounted;
	let dispose;

	return {
		c() {
			img = element("img");
			attr(img, "alt", "arrow Previous");
			if (img.src !== (img_src_value = "/images/_arrows/arrow_" + /*subcat*/ ctx[2] + ".png")) attr(img, "src", img_src_value);
			attr(img, "class", "arrow previous reversed no-select svelte-1lkt4c0");
		},
		m(target, anchor) {
			insert(target, img, anchor);

			if (!mounted) {
				dispose = listen(img, "click", /*onPreviousClick*/ ctx[3]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*subcat*/ 4 && img.src !== (img_src_value = "/images/_arrows/arrow_" + /*subcat*/ ctx[2] + ".png")) {
				attr(img, "src", img_src_value);
			}
		},
		d(detaching) {
			if (detaching) detach(img);
			mounted = false;
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let t;
	let if_block1_anchor;
	let if_block0 = /*showNext*/ ctx[1] && create_if_block_1(ctx);
	let if_block1 = /*showPrevious*/ ctx[0] && create_if_block(ctx);

	return {
		c() {
			if (if_block0) if_block0.c();
			t = space();
			if (if_block1) if_block1.c();
			if_block1_anchor = empty();
		},
		m(target, anchor) {
			if (if_block0) if_block0.m(target, anchor);
			insert(target, t, anchor);
			if (if_block1) if_block1.m(target, anchor);
			insert(target, if_block1_anchor, anchor);
		},
		p(ctx, [dirty]) {
			if (/*showNext*/ ctx[1]) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_1(ctx);
					if_block0.c();
					if_block0.m(t.parentNode, t);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*showPrevious*/ ctx[0]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block(ctx);
					if_block1.c();
					if_block1.m(if_block1_anchor.parentNode, if_block1_anchor);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (if_block0) if_block0.d(detaching);
			if (detaching) detach(t);
			if (if_block1) if_block1.d(detaching);
			if (detaching) detach(if_block1_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { showPrevious = false } = $$props;
	let { showNext = false } = $$props;
	let { subcat = "Reves" } = $$props;
	const dispatch = createEventDispatcher();

	function onPreviousClick(e) {
		e.preventDefault();
		dispatch("previousRequest", {});
	}

	function onNextClick(e) {
		e.preventDefault();
		e.stopPropagation();
		dispatch("nextRequest", {});
	}

	$$self.$$set = $$props => {
		if ("showPrevious" in $$props) $$invalidate(0, showPrevious = $$props.showPrevious);
		if ("showNext" in $$props) $$invalidate(1, showNext = $$props.showNext);
		if ("subcat" in $$props) $$invalidate(2, subcat = $$props.subcat);
	};

	return [showPrevious, showNext, subcat, onPreviousClick, onNextClick];
}

class ArrowsScreen extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { showPrevious: 0, showNext: 1, subcat: 2 });
	}
}

export default ArrowsScreen;