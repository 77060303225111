import { Renderer, Vector3 } from 'three';

export function randRange(min: number, max: number): number{
    if(min == max) return 0;
    if(min > max){
        throw new Error("invalid arguments: min greater than max");
    }
    const range = max - min;

    return Math.random() * range + min;
}

export function resizeRendererToDisplaySize(renderer: Renderer) {
    const dpr = window.devicePixelRatio || 1;
    // console.log("dpi setting", dpr);
    const canvas = renderer.domElement;
    const width = canvas.clientWidth * dpr;
    const height = canvas.clientHeight * dpr;
    const needResize = canvas.width !== width || canvas.height !== height;
    if (needResize) {
        renderer.setSize(width, height, false);
    }
    return needResize;
}

// https://math.stackexchange.com/questions/1585975/how-to-generate-random-points-on-a-sphere
export function randomPointOnSphereSurface(sphereRadius: number): Vector3{
    const u1 = randRange(0, 1);
    const u2 = randRange(0, 1);

    const lat = Math.acos(2 * u1 - 1) - Math.PI / 2;
    const long = 2 * Math.PI * u2
    
    const x	= sphereRadius * Math.cos(lat) * Math.cos(long);	
    const y	= sphereRadius * Math.cos(lat) * Math.sin(long);	
    const z	= sphereRadius * Math.sin(lat);	

    return new Vector3(x, y, z);
}

export function randomPointInSphere(maxRadius: number): Vector3{
    const distanceFromCenter = randRange(0, maxRadius);

    return randomPointOnSphereSurface(distanceFromCenter);
}

export function randomNormalizedVector(): Vector3{
    return new Vector3(randRange(-1, 1), randRange(-1, 1), randRange(-1, 1)).normalize();
}
