

import axios from 'axios';
import { SubcategoryOrderList, SubcategoryType } from '../model/SubcategoryHelpers';
import { Tweet } from '../model/Tweet';

import { jsToSvelteEventProvider, JsToSvelteEventType } from '../svelte/jsToSvelteBus';

class TweetApiService {
    private __baseUrl!: string;

    private _started = false;

    private _timeoutId = -1;

    private _updateInterval = 15000; // 15 seconds
  
    private get baseUrl(): string {
        const prodUrl = 'https://api.demainlalune.ch/'
        const devUrl = 'http://localhost:8080/';
        if (!this.__baseUrl) {
            this.__baseUrl = location.hostname == "localhost" ? devUrl : prodUrl;
        }
        console.log("baseurl", this.__baseUrl);
        return this.__baseUrl;
    }

    private async getTweets(): Promise<Map<SubcategoryType, Tweet[]>> {
        const url = `${this.baseUrl}/tweets`;
        try {
            const resp = await axios.get(url);
            const data = resp.data as any;

            let result = new Map<SubcategoryType, Tweet[]>();

            for(const subcatType of SubcategoryOrderList){
                const tweetCollection = data[subcatType];
                const tweets = tweetCollection.map((t:any) => {
                    const intensity = t['fields']['Intensite'] || null;
                    const text = t['fields']['Texte'];
                    const dateStr = t['fields']['Created'];
                    return new Tweet(intensity, text, dateStr);
                });

                result.set(subcatType, tweets);
            }

            return result;
        } catch (e) {
            console.error(`error on url = ${url}`, e);
            throw e;
        }
    }

    public start(){
        if(this._started){
            return;
        }

        console.log("started tweet api polling process");

        this._started = true;

        this.pingApi();
    }

    private async pingApi(){
        try{
            const tweetMap = await this.getTweets();
            jsToSvelteEventProvider.provide(JsToSvelteEventType.TweetCollectionUpdate, { tweetMap: tweetMap });
        }catch(e){
            // do nothing...
        }

        this._timeoutId = window.setTimeout(() => this.pingApi(), this._updateInterval);
    }
}


export const tweetApiService = new TweetApiService();
