/* ts/svelte/InterviewDigestPlayer.svelte generated by Svelte v3.31.2 */
import {
	HtmlTag,
	SvelteComponent,
	append,
	attr,
	bubble,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	safe_not_equal,
	set_data,
	space,
	text,
	transition_in,
	transition_out
} from "svelte/internal";

import Modal from "./Modal.svelte";
import { onMount } from "svelte";
import { db } from "../model/Db";

function create_if_block(ctx) {
	let div;
	let h1;
	let t0_value = /*interviewDigest*/ ctx[1].TitleOfQuestion + "";
	let t0;
	let t1;
	let h3;
	let t2_value = /*interviewDigest*/ ctx[1].PersonInterviewed.FullNameContributor + "";
	let t2;
	let t3;
	let t4_value = /*interviewDigest*/ ctx[1].PersonInterviewed.TypeOfContributor[0] + "";
	let t4;
	let t5;
	let t6;
	let t7;
	let html_tag;
	let raw_value = /*interviewDigest*/ ctx[1].Interview_Redux + "";
	let if_block = /*interviewDigest*/ ctx[1].MainImage && create_if_block_1(ctx);

	return {
		c() {
			div = element("div");
			h1 = element("h1");
			t0 = text(t0_value);
			t1 = space();
			h3 = element("h3");
			t2 = text(t2_value);
			t3 = text(" (");
			t4 = text(t4_value);
			t5 = text(")");
			t6 = space();
			if (if_block) if_block.c();
			t7 = space();
			html_tag = new HtmlTag(null);
			attr(div, "class", "col svelte-1rvidma");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, h1);
			append(h1, t0);
			append(div, t1);
			append(div, h3);
			append(h3, t2);
			append(h3, t3);
			append(h3, t4);
			append(h3, t5);
			append(div, t6);
			if (if_block) if_block.m(div, null);
			append(div, t7);
			html_tag.m(raw_value, div);
		},
		p(ctx, dirty) {
			if (dirty & /*interviewDigest*/ 2 && t0_value !== (t0_value = /*interviewDigest*/ ctx[1].TitleOfQuestion + "")) set_data(t0, t0_value);
			if (dirty & /*interviewDigest*/ 2 && t2_value !== (t2_value = /*interviewDigest*/ ctx[1].PersonInterviewed.FullNameContributor + "")) set_data(t2, t2_value);
			if (dirty & /*interviewDigest*/ 2 && t4_value !== (t4_value = /*interviewDigest*/ ctx[1].PersonInterviewed.TypeOfContributor[0] + "")) set_data(t4, t4_value);

			if (/*interviewDigest*/ ctx[1].MainImage) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_1(ctx);
					if_block.c();
					if_block.m(div, t7);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty & /*interviewDigest*/ 2 && raw_value !== (raw_value = /*interviewDigest*/ ctx[1].Interview_Redux + "")) html_tag.p(raw_value);
		},
		d(detaching) {
			if (detaching) detach(div);
			if (if_block) if_block.d();
		}
	};
}

// (37:12) {#if interviewDigest.MainImage}
function create_if_block_1(ctx) {
	let img;
	let img_src_value;

	return {
		c() {
			img = element("img");
			if (img.src !== (img_src_value = /*interviewDigest*/ ctx[1].MainImage.thumbnails.large.url)) attr(img, "src", img_src_value);
			attr(img, "alt", "");
		},
		m(target, anchor) {
			insert(target, img, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*interviewDigest*/ 2 && img.src !== (img_src_value = /*interviewDigest*/ ctx[1].MainImage.thumbnails.large.url)) {
				attr(img, "src", img_src_value);
			}
		},
		d(detaching) {
			if (detaching) detach(img);
		}
	};
}

// (30:4) <div class="container" slot="content">
function create_content_slot(ctx) {
	let div;
	let if_block = /*interviewDigest*/ ctx[1] && create_if_block(ctx);

	return {
		c() {
			div = element("div");
			if (if_block) if_block.c();
			attr(div, "class", "container svelte-1rvidma");
			attr(div, "slot", "content");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			if (if_block) if_block.m(div, null);
		},
		p(ctx, dirty) {
			if (/*interviewDigest*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d(detaching) {
			if (detaching) detach(div);
			if (if_block) if_block.d();
		}
	};
}

function create_fragment(ctx) {
	let modal;
	let current;

	modal = new Modal({
			props: {
				isOpen: /*isOpen*/ ctx[0],
				noInteract: false,
				$$slots: { content: [create_content_slot] },
				$$scope: { ctx }
			}
		});

	modal.$on("closeRequest", /*closeRequest_handler*/ ctx[3]);

	return {
		c() {
			create_component(modal.$$.fragment);
		},
		m(target, anchor) {
			mount_component(modal, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const modal_changes = {};
			if (dirty & /*isOpen*/ 1) modal_changes.isOpen = /*isOpen*/ ctx[0];

			if (dirty & /*$$scope, interviewDigest*/ 18) {
				modal_changes.$$scope = { dirty, ctx };
			}

			modal.$set(modal_changes);
		},
		i(local) {
			if (current) return;
			transition_in(modal.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(modal.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(modal, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let interviewDigest;
	let { isOpen = false } = $$props;
	let { payload = null } = $$props;

	function closeRequest_handler(event) {
		bubble($$self, event);
	}

	$$self.$$set = $$props => {
		if ("isOpen" in $$props) $$invalidate(0, isOpen = $$props.isOpen);
		if ("payload" in $$props) $$invalidate(2, payload = $$props.payload);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*payload*/ 4) {
			$: $$invalidate(1, interviewDigest = payload
			? db.getInterviewDigestFormMediaId(payload.id)
			: null);
		}
	};

	return [isOpen, interviewDigest, payload, closeRequest_handler];
}

class InterviewDigestPlayer extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { isOpen: 0, payload: 2 });
	}
}

export default InterviewDigestPlayer;