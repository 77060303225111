
const TWEEN = require('@tweenjs/tween.js');


import { Color, Scene } from 'three';

export const Theme = {
    DARK: "dark",
    LIGHT: "light",
};


const config: any = {
    "dark": {
        skyColor: new Color(0x222222),
        buildingColor: new Color(0x333333),
    },
    "light": {
        skyColor: new Color(0xffffff),
        buildingColor: new Color(0xbbbbbb),
    }
}

export class ThemeManager
{
    private theme = Theme.DARK;
    
    private toggleHandler: () => void;

    private buildingMaterials: any[] = [];

    constructor(private scene: Scene){
        this.toggleHandler = this.toggle.bind(this);
    }

    toggle(){
        this.theme = (this.theme == Theme.DARK) ? Theme.LIGHT : Theme.DARK; 

        new TWEEN.Tween(this.scene)
            .to({ background: config[this.theme].skyColor }) 
            .easing(TWEEN.Easing.Cubic.InOut)
            .start();

        if(this.buildingMaterials){
            for(let i = 0; i < this.buildingMaterials.length; i++){
                const mat = this.buildingMaterials[i];
                
                new TWEEN.Tween(mat)
                    .to({ color: config[this.theme].buildingColor }) 
                    .easing(TWEEN.Easing.Cubic.InOut)
                    .start();
            }

        }
    }

    addGltfScene(scene: any){
        const buildingNames = [
            "map_2osm_buildings", // root
            "map_2.osm_buildings_1", // walls
            "map_2.osm_buildings_2", // roofs
        ];

        this.buildingMaterials = [];

        scene.traverse((item: any) => {
            if (item.name == buildingNames[1] || item.name == buildingNames[2]) {
                this.buildingMaterials.push(item.material);
            }
        });
    }
}