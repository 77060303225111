/* ts/svelte/Modal.svelte generated by Svelte v3.31.2 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	create_slot,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	listen,
	safe_not_equal,
	space,
	stop_propagation,
	toggle_class,
	transition_in,
	transition_out,
	update_slot
} from "svelte/internal";

import { createEventDispatcher } from "svelte";
const get_content_slot_changes = dirty => ({});
const get_content_slot_context = ctx => ({});

// (16:0) {#if isOpen}
function create_if_block(ctx) {
	let div2;
	let div0;
	let t;
	let div1;
	let current;
	let mounted;
	let dispose;
	const content_slot_template = /*#slots*/ ctx[4].content;
	const content_slot = create_slot(content_slot_template, ctx, /*$$scope*/ ctx[3], get_content_slot_context);

	return {
		c() {
			div2 = element("div");
			div0 = element("div");
			t = space();
			div1 = element("div");
			if (content_slot) content_slot.c();
			attr(div0, "class", "backdrop svelte-goj9rw");
			attr(div1, "class", "content svelte-goj9rw");
			toggle_class(div1, "no-interact", /*noInteract*/ ctx[1]);
			attr(div2, "class", "modal svelte-goj9rw");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);
			append(div2, t);
			append(div2, div1);

			if (content_slot) {
				content_slot.m(div1, null);
			}

			current = true;

			if (!mounted) {
				dispose = listen(div0, "click", stop_propagation(/*broadcastCloseRequest*/ ctx[2]));
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (content_slot) {
				if (content_slot.p && dirty & /*$$scope*/ 8) {
					update_slot(content_slot, content_slot_template, ctx, /*$$scope*/ ctx[3], dirty, get_content_slot_changes, get_content_slot_context);
				}
			}

			if (dirty & /*noInteract*/ 2) {
				toggle_class(div1, "no-interact", /*noInteract*/ ctx[1]);
			}
		},
		i(local) {
			if (current) return;
			transition_in(content_slot, local);
			current = true;
		},
		o(local) {
			transition_out(content_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div2);
			if (content_slot) content_slot.d(detaching);
			mounted = false;
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let if_block_anchor;
	let current;
	let if_block = /*isOpen*/ ctx[0] && create_if_block(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			if (/*isOpen*/ ctx[0]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*isOpen*/ 1) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	let { isOpen = false } = $$props;
	let { noInteract = false } = $$props;
	const dispatch = createEventDispatcher();

	function broadcastCloseRequest() {
		console.log("click");
		dispatch("closeRequest", {});
	}

	$$self.$$set = $$props => {
		if ("isOpen" in $$props) $$invalidate(0, isOpen = $$props.isOpen);
		if ("noInteract" in $$props) $$invalidate(1, noInteract = $$props.noInteract);
		if ("$$scope" in $$props) $$invalidate(3, $$scope = $$props.$$scope);
	};

	return [isOpen, noInteract, broadcastCloseRequest, $$scope, slots];
}

class Modal extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { isOpen: 0, noInteract: 1 });
	}
}

export default Modal;