export const SpatialAudiosCollection = [
    {
        filename: "servette_gare.mp3",
        coordinates: [ 6.1390239000320435, 46.209477446211814 ],
        refDistance: 55,
        rollof: 1.3,
    },
    {
        filename: "moleson.mp3",
        coordinates: [ 6.130768060684204, 46.209596239837644],
        refDistance: 45,
        rollof: 1.3,
    },
    {
        filename: "rueServette.mp3",
        coordinates: [ 6.1335790157318115, 46.21196463412732],
        refDistance: 25,
        rollof: 1.3,
    },
]