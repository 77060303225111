export function collate(stringArray: string[], separator = " | ") {
    return stringArray.reduce((accum, next) => {
        if (accum !== "") {
            accum += `${separator}`;
        }
        return accum + next;
    }, "");
}

export function cacheBustUrl(url: string){
    return `${url}?v=${new Date().getTime()}`;
}

export function randomItemFromArray(array: any[]){
    if(!array || array.length == 0){
        return null;
    }

    if(array.length == 1){
        return array[0];
    }
    
    return array[Math.floor(Math.random() * array.length)];
}

export function shuffle(array: any[]) {
    let currentIndex = array.length;
    let randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}