/* ts/svelte/SoundCloud.svelte generated by Svelte v3.31.2 */
import {
	SvelteComponent,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal
} from "svelte/internal";

import { onMount, onDestroy } from "svelte";

function create_fragment(ctx) {
	let iframe;
	let iframe_src_value;

	return {
		c() {
			iframe = element("iframe");
			attr(iframe, "title", "soundcloud");
			attr(iframe, "width", "100%");
			attr(iframe, "height", "300");
			attr(iframe, "scrolling", "no");
			attr(iframe, "frameborder", "no");
			attr(iframe, "allow", "autoplay");
			if (iframe.src !== (iframe_src_value = /*src*/ ctx[0])) attr(iframe, "src", iframe_src_value);
		},
		m(target, anchor) {
			insert(target, iframe, anchor);
		},
		p(ctx, [dirty]) {
			if (dirty & /*src*/ 1 && iframe.src !== (iframe_src_value = /*src*/ ctx[0])) {
				attr(iframe, "src", iframe_src_value);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(iframe);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { clipUrl = null } = $$props;
	let src = "";

	onMount(() => {
		$$invalidate(0, src = `https://w.soundcloud.com/player/?url=${encodeURIComponent(clipUrl)}`);
		$$invalidate(0, src += "&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true");
	});

	$$self.$$set = $$props => {
		if ("clipUrl" in $$props) $$invalidate(1, clipUrl = $$props.clipUrl);
	};

	return [src, clipUrl];
}

class SoundCloud extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { clipUrl: 1 });
	}
}

export default SoundCloud;