/* ts/svelte/TweetCard.svelte generated by Svelte v3.31.2 */
import {
	SvelteComponent,
	append,
	attr,
	destroy_each,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	space,
	text as text_1
} from "svelte/internal";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[4] = list[i];
	child_ctx[6] = i;
	return child_ctx;
}

// (11:4) {#if intensity}
function create_if_block(ctx) {
	let div;
	let each_value = Array(/*intensity*/ ctx[1]);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div, "class", "intensity svelte-79esk3");
		},
		m(target, anchor) {
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div, null);
			}
		},
		p(ctx, dirty) {
			if (dirty & /*intensityImageSource, intensity*/ 6) {
				each_value = Array(/*intensity*/ ctx[1]);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
		}
	};
}

// (13:12) {#each Array(intensity) as _, i}
function create_each_block(ctx) {
	let img;
	let img_src_value;

	return {
		c() {
			img = element("img");
			if (img.src !== (img_src_value = /*intensityImageSource*/ ctx[2])) attr(img, "src", img_src_value);
			attr(img, "alt", "");
			attr(img, "class", "svelte-79esk3");
		},
		m(target, anchor) {
			insert(target, img, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*intensityImageSource*/ 4 && img.src !== (img_src_value = /*intensityImageSource*/ ctx[2])) {
				attr(img, "src", img_src_value);
			}
		},
		d(detaching) {
			if (detaching) detach(img);
		}
	};
}

function create_fragment(ctx) {
	let div1;
	let t0;
	let div0;
	let t1;
	let if_block = /*intensity*/ ctx[1] && create_if_block(ctx);

	return {
		c() {
			div1 = element("div");
			if (if_block) if_block.c();
			t0 = space();
			div0 = element("div");
			t1 = text_1(/*text*/ ctx[0]);
			attr(div0, "class", "text svelte-79esk3");
			attr(div1, "class", "tweet-card svelte-79esk3");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			if (if_block) if_block.m(div1, null);
			append(div1, t0);
			append(div1, div0);
			append(div0, t1);
		},
		p(ctx, [dirty]) {
			if (/*intensity*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div1, t0);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty & /*text*/ 1) set_data(t1, /*text*/ ctx[0]);
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div1);
			if (if_block) if_block.d();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let intensityImageSource;
	let { text } = $$props;
	let { intensity } = $$props;
	let { currentSubcat = "Reves" } = $$props;

	$$self.$$set = $$props => {
		if ("text" in $$props) $$invalidate(0, text = $$props.text);
		if ("intensity" in $$props) $$invalidate(1, intensity = $$props.intensity);
		if ("currentSubcat" in $$props) $$invalidate(3, currentSubcat = $$props.currentSubcat);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*currentSubcat*/ 8) {
			$: $$invalidate(2, intensityImageSource = currentSubcat == "Archives"
			? "/images/happy.svg"
			: "/images/sad.svg");
		}
	};

	return [text, intensity, intensityImageSource, currentSubcat];
}

class TweetCard extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { text: 0, intensity: 1, currentSubcat: 3 });
	}
}

export default TweetCard;