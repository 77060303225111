import distance from '@turf/distance';
import bearing from '@turf/bearing';
import { point } from '@turf/helpers';
/* 
bbox
north max lat: 46.211882967058834
south min lat: 46.20615112037697
east max lon: 6.140542030334473
west min lon : 6.1278605461120605
*/

/*
SITG bbox requires CH1903+/LV95 format
conversion can be done here
https://www.swisstopo.admin.ch/en/maps-data-online/calculation-services/navref.html
east max: 2499824.668
east min: 2498846.123
north max: 1118672.482
north min: 1118035.388
*/
const minLat = 46.20615112037697; 
const maxLat = 46.211882967058834; 
const minLon = 6.1278605461120605
const maxLon = 6.140542030334473;


const midLat = (maxLat - minLat) / 2;
const midLon = (maxLon - minLon) / 2;

const midPoint = point([minLon + midLon, minLat + midLat]);
const originCoord = midPoint;

class vec2{
    constructor(public x: number, public y: number){ }

    subtract(other: vec2): vec2 {
        return new vec2(this.x - other.x, this.y - other.y);
    }

    multiplied(factor: number): vec2 {
        return new vec2(this.x * factor, this.y * factor);
    }

    length(): number{
        return Math.sqrt(this.x * this.x + this.y * this.y);
    }

    normalized(): vec2{
        const len = this.length();
        if(len == 0){
            console.error("divide by 0");
        }
        return new vec2(this.x / len, this.y / len);
    }
}

function vecFromCoord(coord: any): vec2{
    return new vec2(coord.geometry.coordinates[0], coord.geometry.coordinates[1]);
}

export function latLonToXY(coord: any): {x: number, y: number} {
    // const coordAsVec = vecFromCoord(coord);
    // const originAsVec = vecFromCoord(originCoord); 

    // const fromOriginToCoordAsVec = coordAsVec.subtract(originAsVec).normalized(); 
    // console.log(fromOriginToCoordAsVec.length());

    const distanceOriginToCoord = distance(coord, originCoord, { units: "meters" });

    // console.log("from", coord, "to", originCoord, "distance", distanceOriginToCoord);

    const northPoleBearing = bearing(originCoord, coord);

    const convertBearing = (b) => {
        return ((b - 180) + 90) * -1;
    }
    const convertedBearing = convertBearing(northPoleBearing);

    const x = Math.cos(convertedBearing * Math.PI / 180) * distanceOriginToCoord;
    const y = Math.sin(convertedBearing * Math.PI / 180) * distanceOriginToCoord;

    // console.log("bearing", northPoleBearing, "converted bearing", convertedBearing);
    const result = {
        x:x,
        y:-y, // z index is inverted
    };
    return result;
}

